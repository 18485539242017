import React, { useState, useEffect } from 'react';
import ItemPayMethodModal from './itemPayMethod/itemPayMethodModal'
import './boxPayMethod.scss'
import { ecommerceService } from "../../services/ecommerce.service";

const BoxPayMethodModal = ({ onCloseClick, showBalancePay, showBalanceOnly, productPurchase }) => {
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        ecommerceService.getPaymentMethods()
            .then((result => {
                setPaymentMethods(result);
            }));
    }, []);

    return (
        <div className="box_paymethod">
            {
                paymentMethods.map(method => {
                    if (showBalanceOnly)
                    {
                        return (method.paymentMethodId == 15 && showBalancePay)  && <ItemPayMethodModal key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} showBalanceOnly = { true } productPurchase = { productPurchase } />
                    }   
                    else
                    {
                        return (method.paymentMethodId != 15 || showBalancePay)  && <ItemPayMethodModal key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} />
                    }                 
                })
            }
        </div>
    )
}

export default BoxPayMethodModal