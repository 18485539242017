import { callApi } from '../service'
import swal from "sweetalert"

export const productService = {
    create,
    update,
    getAll,
    getById,
    getProviders,
    getProducts
};

async function create(data) {
    data.productId = parseInt(data.productId.split("-")[0]);
    return await callApi('/BackOfficeProducts/Commission', 'POST', data)
        .then(result => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/products");
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function update(data) {
    return await callApi('/BackOfficeProducts/Commission', 'Patch', data)
        .then(result => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/products");
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function getAll() {
    return callApi('/BackOfficeProducts/Commission', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}

async function getById(id) {
    return callApi('/BackOfficeProducts/Commission/' + id, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}


async function getProviders(saleChannelId) {
    return callApi(`/BackOfficeProducts/SaleChannel/${saleChannelId}/Providers`, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}

async function getProducts(saleChannelId, providerId) {
    return callApi(`/BackOfficeProducts/SaleChannel/${saleChannelId}/Provider/${providerId}`, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}