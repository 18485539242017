import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { companyService } from '../../services/control/company.service';
import { levelsService } from '../../services/backoffice/levels.service';
import { ecommerceService } from "../../services/ecommerce.service";

import { Link } from 'react-router-dom';

export function EditCompany(props) {
   
    const [levels, setLevels] = useState([]);
    const onSave = () => {
        inputs.levelId = parseInt(inputs.levelId);
        inputs.minimunAmount = parseFloat(inputs.minimunAmount);
        inputs.paymentMethodId = inputs.paymentMethodId;
        if (inputs.companyId && inputs.companyId > 0)
            companyService.edit(inputs);
        else {
            companyService.create(inputs);
        }
    };

    const [company, setCompany] = useState({
        companyId: 0,
        companyName: "",
        businessName: "",
        urlRedVoucher: "",
        telephone1: "",
        telephone2: "",
        username: "",
        password: "",
        levelId: 0,
        active: true,
        minimunAmount: 0,
        paymentMethodId: 0
    });

    const [paymentMethods, setPaymentMethods] = useState([]);
    
    useEffect(() => {
        ecommerceService.getPaymentMethods()
            .then((result => {
                setPaymentMethods(result);
            }));
        getLevels();
        if (props.companyId != 0) {
            companyService.getById(props.companyId).then((result) => {
                setCompany({
                    companyId: result.companyId,
                    companyName: result.companyName,
                    businessName: result.businessName,
                    urlRedVoucher: result.urlRedVoucher,
                    telephone1: result.telephone1,
                    telephone2: result.telephone2,
                    username: result.password,
                    password: result.password,
                    levelId: result.levelId,
                    active: result.active,
                    minimunAmount: result.minimunAmount,
                    paymentMethodId: result.paymentMethodId
                });
            });
        }
    }, [props.company]);

    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, company);

    const handleChangeLevel = (event) => {
        setInputs({ ...inputs, levelId: event.target.value });
    }
    const handleChangeMethod = (event) => {
        setInputs({ ...inputs, paymentMethodId: event.target.value });
    }

    const getLevels = () => {
        levelsService.getAll()
            .then((result) => setLevels(result));
    }
    
    return (
        <div className="wrapper_form_company card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.companyId !== 0 ? "Editar " : "Nueva "}Empresa</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <input type="hidden" name="companyId" onChange={handleInputChange} value={inputs.companyId} />
                            <div className="form-group">
                                <label htmlFor="companyName">Nombre</label>
                                <input type="text" name="companyName" className="form-control" onChange={handleInputChange} value={inputs.companyName} placeholder="Identificador de la empresa" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="businessName">Nombre de la Empresa</label>
                                <input type="text" name="businessName" className="form-control" onChange={handleInputChange} value={inputs.businessName} placeholder="Nombre o razón social de la empresa" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="urlRedVoucher">Dominio</label>
                                <input type="text" name="urlRedVoucher" className="form-control" onChange={handleInputChange} value={inputs.urlRedVoucher} placeholder="Dominio corporativo" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label for="levelId">Nivel</label>
                                <select className="form-control" id="levelId" disabled={ props.companyId != 0 ? "disabled" : "" } name="levelId" onChange={handleChangeLevel} value={inputs.levelId} required>
                                    <option key="0" value="0">Selecciona una opción</option>
                                    {
                                        levels.map(item => <option key={item.levelId} value={item.levelId}>{item.description}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="telephone1">Teléfono oficina</label>
                                <input type="text" name="telephone1" className="form-control" onChange={handleInputChange} value={inputs.telephone1} placeholder="Teléfono principal" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="telephone2">Teléfono alterno</label>
                                <input type="text" name="telephone2" className="form-control" onChange={handleInputChange} value={inputs.telephone2} placeholder="Teléfono alterno" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="minimunAmount">Monto de abono mínimo</label>
                                <input type="number" name="minimunAmount" className="form-control" onChange={handleInputChange} value={inputs.minimunAmount} min="1" step="1" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label for="paymentMethod">Método de pago</label>
                                <select className="form-control" id="paymentMethod" name="paymentMethod" onChange={handleChangeMethod} value={inputs.paymentMethodId} required>
                                    <option key="0" value="0">Selecciona una opción</option>
                                    {
                                        paymentMethods.map(method => <option key={method.paymentMethodId} value={method.paymentMethodId}>{method.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    {props.companyId == 0 ?
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="username">Usuario de inicial</label>
                                    <input type="email" name="username" className="form-control" onChange={handleInputChange} value={inputs.username} placeholder="Correo corporativo" required />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña</label>
                                    <input type="password" name="password" className="form-control" onChange={handleInputChange} value={inputs.password} placeholder="Contraseña" required />
                                </div>
                            </div>
                        </div>
                        : null}
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/AdminCompany" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   