import React, { useState, useEffect } from "react";
import { stringHelper } from "../../helpers/string.helper";
import {
  Modal,
  ModalBody,
  CustomInput,
  FormGroup,
} from "reactstrap";
import { ecommerceService } from "../../services/ecommerce.service";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import { useDispatch } from "react-redux";
import BoxPayMethodModal from "../boxPayMethod/boxPayMethodModal";
import swal from "sweetalert";
import $ from 'jquery'

const ProviderModal = ({
  modal,
  setModal,
  provider,
  userProfileBalance,
  isBalanceOnly,
}) => {
  const dispatch = useDispatch();
  const [showSaveReferencePanel, setshowSaveReferencePanel] = useState(false);
  const [inputSave, setInputSave] = useState(false);
  const [savedReferenceList, setSavedReferenceList] = useState([]);
  const [providerProducts, setProviderProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    product: null,
    provider: null,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [referenceData, setReferenceData] = useState({
    reference: "",
    confirm: "",
    name: "",
    amount: "",
    isSaveReference: false,
  });

  const numbers = /^[0-9]+$/;

  const toggleModal = () => {
    setshowSaveReferencePanel(savedReferenceList.length > 0);
    setInputSave(false);
    setReferenceData({
      reference: "",
      confirm: "",
      name: "",
      amount: "",
      isSaveReference: false,
    });
    $('body').removeClass('modal_is_open');
    setSelectedProduct({ product: null, provider: null });
    // setShowPayButton(false);
    setModal(!modal);
    setShowSummary(false);
  };

  useEffect(() => {
    if (modal) {
      if (provider.id)
      ecommerceService.getProviderProducts(provider.id).then((result) => {
        setProviderProducts(result);
        if (result.products && result.products[0].isVariable) {
          setSelectedProduct({ product: result.products[0], provider });
        }
        userProfileService
          .getSavedReference(
            provider.providerInputValueDto.providerInputValueId
          )
          .then((resData) => {
            setSavedReferenceList(resData);
            setshowSaveReferencePanel(resData.length > 0);
          });
      });
    }
  }, [provider, modal]);

  const togglePanel = () => {
    if (selectedProduct.product) {
      setshowSaveReferencePanel(!showSaveReferencePanel);
      setReferenceData({
        reference: "",
        confirm: "",
        name: "",
        amount: "",
        isSaveReference: false,
      });
      setSelectedProduct({ ...selectedProduct });
    } else {
      swal({
        icon: "warning",
        title: "Validación",
        text: "Selecciona primero un monto",
        button: false,
        closeOnEsc: true,
        timer: 2250,
      });
    }
  };

  const clickInputSave = () => {
    setInputSave(!inputSave);

    if (!inputSave) {
      setReferenceData({ ...referenceData, name: "" });
    }

    // setShowPayButton(false);
  };

  const onChange = (event) => {
    switch (event.target.id) {
      case "reference":
        setReferenceData({ ...referenceData, reference: event.target.value });
        break;
      case "referenceConfirm":
        setReferenceData({ ...referenceData, confirm: event.target.value });
        break;
      case "referenceName":
        setReferenceData({ ...referenceData, name: event.target.value });
        break;
      case "amount":
        setReferenceData({ ...referenceData, amount: event.target.value });
        break;
    }
    selectedProduct.product.reference = referenceData;
    selectedProduct.product.reference.isSaveReference = inputSave;
  };

  const onSelectReference = (selectedReference) => {
    setReferenceData({
      ...referenceData,
      reference: selectedReference,
      confirm: selectedReference,
      isSaveReference: inputSave,
    });
    setSelectedProduct({
      ...selectedProduct,
      product: { ...selectedProduct.product, reference: referenceData },
    });
  };

  const validatePurchaseItem = () => {
    let quantity = 1;
    let amount = 0.0;
    if (!selectedProduct.product) {
      swal({
        icon: "error",
        title: "Validación",
        text: "Debe indicarse un monto para pagar",
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }
    if (
      (selectedProduct.product.productPrice == undefined ||
        selectedProduct.product.productPrice <= 0) &&
      (referenceData.amount == undefined || referenceData.amount <= 0)
    ) {
      swal({
        icon: "error",
        title: "Validación",
        text: "Debe indicarse un monto para pagar",
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }
    if (referenceData.reference === "") {
      swal({
        icon: "error",
        title: "Validación",
        text: "Debe indicarse una referencia para poder continuar",
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }
    if (
      referenceData.reference &&
      referenceData.reference !== referenceData.confirm
    ) {
      swal({
        icon: "error",
        title: "Validación",
        text: "La referencia capturada y la confirmación deben coincidir para poder continuar",
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }

    if (
      referenceData.reference &&
      provider.providerInputValueDto.inputType === "number" &&
      !referenceData.reference.match(numbers)
    ) {
      swal({
        icon: "error",
        title: "Validación",
        text: `Las referencias de ${provider.name} deben contener únicamente números.`,
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }

    if (
      referenceData.reference &&
      referenceData.reference.length !== provider.providerInputValueDto.size
    ) {
      swal({
        icon: "error",
        title: "Validación",
        text: `La referencias de ${provider.name} deben ser de ${
          provider.providerInputValueDto.size
        } ${
          provider.providerInputValueDto.inputType === "number"
            ? "dígitos"
            : "caracteres"
        }.`,
        button: false,
        closeOnEsc: true,
        timer: 2250,
        onClose: setShowSummary(false),
      });
      return;
    }

    if (inputSave) {
      if (
        referenceData.name == null ||
        referenceData.name == undefined ||
        referenceData.name == ""
      ) {
        swal({
          icon: "error",
          title: "Validación",
          text: "Debe elegir un nombre para la nueva referencia",
          button: false,
          closeOnEsc: true,
          timer: 2250,
          onClose: setShowSummary(false),
        });
        return;
      }
    }
    if (referenceData.amount && selectedProduct.product.productPrice === 0) {
      setSelectedProduct({
        ...selectedProduct,
        product: {
          ...selectedProduct.product,
          productPrice: Number(referenceData.amount),
          cant: quantity,
        },
      });
    }

    selectedProduct.product.reference = referenceData;
    selectedProduct.product.reference.isSaveReference = inputSave;
    setShowSummary(true);
  };

  const onSelect = (provider, event) => {
    let index = event.target.selectedIndex;
    let selectedString = event.target[index].getAttribute("data-product");
    const selectedObject = JSON.parse(selectedString);
    selectedObject.cant = 1;
    if (selectedObject != null)
      setSelectedProduct({ product: selectedObject, provider });
    else {
      setReferenceData({ ...referenceData, reference: null, confirm: null });
      setSelectedProduct({ product: null, provider: null });
    }
  };

  return (
    <Modal
      isOpen={modal}
      className="modal_pay_services modal-dialog-centered modal-dialog-scrollable modal-sm"
    >
      <span className="btn_close" onClick={toggleModal}>
        <i className="fas fa-times"></i>
      </span>
      <ModalBody>
        <div className="drawer_brand_img">
          <img
            src={provider && provider.logoCrls}
            alt={provider && provider.name}
          />
        </div>
        <div className={showSummary === false ? "" : "d-none"}>
          <div className="row">
            {providerProducts.products &&
            providerProducts.products[0].isVariable ? (
              <div className="col-12">
                <div className="form-group">
                  <label for="my-select">Monto</label>
                  <input
                    type="number"
                    min="0.01"
                    step="0.01"
                    className="form-control"
                    id="amount"
                    placeholder="Indique el monto de pago del servicio"
                    value={referenceData && referenceData.amount}
                    onChange={onChange}
                  />
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className="form-group">
                  <label for="my-select">Seleccione</label>
                  <select
                    className="form-control"
                    id="my-select"
                    onChange={(event) =>
                      onSelect(providerProducts.provider, event)
                    }
                  >
                    <option value="">Seleccione un monto</option>
                    {providerProducts.products &&
                      providerProducts.products.map((item) => {
                        return (
                          <option
                            key={item.productId}
                            value={item}
                            data-product={JSON.stringify(item)}
                          >
                            {item.name}{" "}
                          </option>
                        );
                      })}
                    ;
                  </select>
                </div>
              </div>
            )}
          </div>
          {showSaveReferencePanel ? (
            <fieldset className="field_group">
              <legend className="mb-0">
                Mis{" "}
                {provider.providerInputValueDto &&
                  `${provider.providerInputValueDto.inputName.toLowerCase()}s guardados`}{" "}
              </legend>
              {savedReferenceList &&
                savedReferenceList.map((item) => {
                  return (
                    <div
                      className="custom-control custom-radio"
                      key={`"div_${item.userProfileInputValueId}`}
                    >
                      <input
                        type="radio"
                        id={item.userProfileInputValueId}
                        name="customRadio"
                        className="custom-control-input"
                        key={`"radio_${item.userProfileInputValueId}`}
                        onChange={() => onSelectReference(item.value)}
                        disabled={selectedProduct.product == null}
                      />
                      <label
                        className="custom-control-label"
                        for={item.userProfileInputValueId}
                        key={`"label_${item.userProfileInputValueId}`}
                      >
                        <h6 className="mb-0">
                          <b>{item.keyName}</b>
                        </h6>
                        <p>{item.value}</p>
                      </label>
                    </div>
                  );
                })}
              <div>
                <span className="link_action" onClick={() => togglePanel()}>
                  <i className="far fa-plus-square"></i> Agregar Nuevo
                </span>
              </div>
            </fieldset>
          ) : (
            <fieldset className="field_group">
              <legend className="mb-0"> Captura de referencia {} </legend>
              {savedReferenceList.length > 0 ? (
                <span className="link_action" onClick={() => togglePanel()}>
                  <i className="far fa-bookmark"></i> Mis{" "}
                  {provider.providerInputValueDto &&
                    `${provider.providerInputValueDto.inputName.toLowerCase()}s`}
                </span>
              ) : null}
              <div>
                <br />
                <div className="form-group">
                  <label for="my-input">
                    {provider.providerInputValueDto &&
                      stringHelper.upperCapital(
                        provider.providerInputValueDto.inputName
                      )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="reference"
                    value={referenceData && referenceData.reference}
                    onChange={onChange}
                    disabled={selectedProduct.product == null}
                  />
                </div>
                <div className="form-group">
                  <label for="my-input">
                    Confirmar{" "}
                    {provider.providerInputValueDto &&
                      provider.providerInputValueDto.inputName.toLowerCase()}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="referenceConfirm"
                    value={referenceData && referenceData.confirm}
                    onChange={onChange}
                    disabled={selectedProduct.product == null}
                  />
                </div>

                <fieldset
                  className={`field_group save_ref mb-3 ${
                    inputSave ? "save-ok" : ""
                  } `}
                >
                  <legend className="mb-0">Nombre de la referencia</legend>
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      id="referenceName"
                      placeholder={`para identificar tu ${
                        provider.providerInputValueDto &&
                        provider.providerInputValueDto.inputName.toLowerCase()
                      }`}
                      value={referenceData && referenceData.name}
                      onChange={onChange}
                      required={inputSave}
                      maxLength="20"
                    />
                  </div>
                </fieldset>
                {providerProducts.products &&
                !providerProducts.products[0].isVariable ? (
                  <div>
                    <FormGroup>
                      <CustomInput
                        type="switch"
                        id="exampleCustomSwitch"
                        name="customSwitch"
                        label={`¿Deseas guardarlo para posteriores recargas?`}
                        onClick={clickInputSave}
                        disabled={selectedProduct.product == null}
                      />
                    </FormGroup>
                  </div>
                ) : null}
              </div>
            </fieldset>
          )}
          <div className="text-center">
            <hr />
            <button
              type="button"
              className="btn primary minimum"
              onClick={validatePurchaseItem}
            >
              {" "}
              Confirmar{" "}
            </button>
          </div>
        </div>

        <div className={showSummary === true ? "text-center" : "d-none"}>
          <hr />
          <fieldset>
            <h6>
              <b>RESUMEN DE COMPRA</b>
            </h6>
            <hr />
            <p className="text-left mb-2">
              {selectedProduct.provider && selectedProduct.provider.name}
            </p>
            {providerProducts.products &&
            providerProducts.products[0].isVariable === true ? (
              <>
                <p className="text-left mb-2">
                  {selectedProduct.product &&
                    selectedProduct.product.name +
                      " - Monto: " +
                      selectedProduct.product.productPrice}
                </p>
              </>
            ) : (
              <>
                <p className="text-left mb-2">
                  {selectedProduct.product && selectedProduct.product.name}
                </p>
              </>
            )}

            <p className="text-left mb-0">
              {referenceData &&
                provider.providerInputValueDto &&
                `${provider.providerInputValueDto.inputName}: ${referenceData.reference}`}
            </p>
          </fieldset>

          <div className="row align-items-center">
          <div className="col text-center">
              <BoxPayMethodModal
                showBalancePay={true}
                showBalanceOnly={true}
                productPurchase={selectedProduct.product}
              />
            </div>
            <div className="col text-center">
              <button
                type="button"
                className="btn secundary minimum"
                onClick={() => setShowSummary(false)}
              >
                Regresar
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProviderModal;
