import React, { useState, useEffect } from 'react';
import { ToolDiscount } from '../../../components/toolDiscount/toolDiscount'
import PurchasedItem from './purchasedItem';
import { PaginatedPage } from './pagination';
import './shoppingHistory.scss'
import { ecommerceService } from '../../../services/ecommerce.service';
import { backendService } from "../../../services/backend.service";


export function ShoppingHistory() {

    const [shoppingHistory, setShoppingHistory] = useState([]);
    const [userProfileBalance, setUserProfileBalance] = useState({ creditBalance: 0, prepaidBalance: 0 });

    const [page, setPage] = useState(1);
    const totalPages = shoppingHistory.length > 0 ? shoppingHistory.length : 1;

    useEffect(() => {
        ecommerceService.getPurchaseOrder()
            .then(result => setShoppingHistory(result));

            getUserProfileBalance();

    }, []);


    const getUserProfileBalance = () => {
        backendService.getUserProfileBalance()
            .then(result => setUserProfileBalance(result));
    }

    return (
        <section className="wrapper_shoppingHistory padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium">Mis compras</h2>
                    <ToolDiscount userProfileBalance = { userProfileBalance }/>
                </div>
            </div>
            <div className="contain_shop_history">
                <div className="boxes_hop_history">
                    {
                        shoppingHistory.length > 0
                            ? shoppingHistory[page - 1].map(item => {
                                return <PurchasedItem {...item} />
                            })
                            : ""
                    }
                    <div className="paginated_page">
                        <PaginatedPage
                            totalPages={totalPages}
                            currentPage={page}
                            onChange={page => setPage(page)}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
