import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { productService } from '../../services/backoffice/product.service';

import './products.scss'

export function SearchProducts(props) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [data, setData] = useState([]);

    const getAll = () => {
        productService.getAll().then((result) => {
            if (result) {
                setData(result);
            }
        });
    }

    useEffect(() => {
        getAll();
    }, []);

    const countrySetting = useSelector(state => state.countrySetting);

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium">Intermediario/Compañia</th>
                        <th className="text fnt_medium">Proveedor </th>
                        <th className="text fnt_medium">Canal de venta</th>
                        <th className="text fnt_medium">Producto</th>
                        <th className="text fnt_medium">Valor Facial/Precio</th>
                        {roleName != 'Administrador' && <th className="text fnt_medium">Costo</th>}
                        <th className="text fnt_medium">Descuento %</th>
                        <th className="text fnt_medium">Precio Final</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map(item => (
                            <tr key={item.commissionProductId}>
                                <td><Link className="link_data" to={"/details-product/" + item.commissionProductId}>{item.companyName}</Link></td>
                                <td>{item.providerName}</td>
                                <td>{item.saleChannelName}</td>
                                <td><Link className="link_data" to={"/details-product/" + item.commissionProductId}>{item.productName}</Link></td>
                                <td>{countrySetting.currency}{(Number(item.value)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                {roleName != 'Administrador' && <td>{countrySetting.currency}{(Number(item.cost)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>}
                                <td><Link className="link_data" to={"/details-product/" + item.commissionProductId}>{item.commission} %</Link></td>
                                <td>{countrySetting.currency}{(Number(item.price)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div >
    )
}