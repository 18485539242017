import React, { useEffect, useState } from 'react';
import { Input } from "reactstrap";
import { GeneralUpload } from '../../../components/Helpers/generalUpload';
import useInputForm from '../../../components/CustonHooks/FormHook'
import { useCompanyHoliday } from '../../../services/companyHoliday.service'


export function EditNotice(props) {
    const onSave = () => {
        if (inputs.CompanyHolidayId && inputs.CompanyHolidayId > 0)
            useCompanyHoliday.updateCompanyHoliday({ CompanyHolidayId: parseInt(inputs.CompanyHolidayId), Description: inputs.Description, Date: inputs.Date, WorkingDay: inputs.WorkingDay, EndDate: inputs.EndDate, StartDate: inputs.StartDate, TypeId: 1 }, localfiles).then((result) => {
                if (props.onSave)
                    props.onSave(result);
            });
        else
            useCompanyHoliday.createCompanyHoliday({ Description: inputs.Description, Date: inputs.Date, WorkingDay: inputs.WorkingDay, EndDate: inputs.EndDate, StartDate: inputs.StartDate, TypeId: 1 }, localfiles).then((result) => {
                if (props.onSave)
                    props.onSave(result);
            });
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit)
            props.onCancelEdit();
    };
    const handleOnChangeFile = (files) => {
        setLocalFiles(files);
    }

    const [CompanyHoliday, setCompanyHoliday] = useState({ CompanyHolidayId: "", Description: "", Date: "", WorkingDay: false, StartDate: "", EndDate: ""});
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, CompanyHoliday);
    const [localfiles, setLocalFiles] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        let newP = {};
        if (props.currentCompanyHoliday) {
            newP = { CompanyHolidayId: props.currentCompanyHoliday.CompanyHolidayId.toString(), Description: props.currentCompanyHoliday.Description, Date: formatDate(new Date(props.currentCompanyHoliday.Date)), WorkingDay: props.currentCompanyHoliday.WorkingDay, EndDate: formatDate(new Date(props.currentCompanyHoliday.EndDate)), StartDate: formatDate(new Date(props.currentCompanyHoliday.StartDate)), TypeId: 1 };
            setCompanyHoliday(newP);
            useCompanyHoliday.getCompanyHolidayWithFilesById(newP.CompanyHolidayId).then((result) => {
                setImages(result.files);
            });
        }
        else
            setCompanyHoliday({ CompanyHolidayId: "", Description: "", Date: "", WorkingDay: false, StartDate: "", EndDate: "", TypeId: 0 });
    }, [props.currentCompanyHoliday]);

    const formatDate = (date) => {

        let datestring = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        return datestring;
    };

    return (
        <form onSubmit={handleSubmit}>
            <fieldset className='mt-4 mb-5'>
                <legend className="text fnt_medium">Nuevo / Editar Aviso</legend>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="Description">Descripción</label>
                            <Input type="text" name="Description" placeholder="Aviso" onChange={handleInputChange} value={inputs.Description} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="StartDate">Fecha</label>
                            <Input type="date" name="Date" placeholder="Fecha de Evento" onChange={handleInputChange} value={inputs.Date} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="StartDate">Fecha de inicio</label>
                            <Input type="date" name="StartDate" placeholder="Fecha inicial" onChange={handleInputChange} value={inputs.StartDate} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label for="EndDate">Fecha de final</label>
                            <Input type="date" name="EndDate" placeholder="Fecha Final" onChange={handleInputChange} value={inputs.EndDate} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <GeneralUpload TitleLabel={"Images"} handleOnChangeFiles={handleOnChangeFile} />
                    </div>
                    {images && images.length > 0 ?
                        <div className="col-md-4">
                            <img src={`data:image/jpeg;base64,${images[0].file}`} style={{ width: '125px' }} />

                        </div> : null}
                </div>
                <hr />
                <div>
                    <button className="btn secundary minimum ml-1 mr-1" type="button" onClick={handleOnCancel} >Cancelar</button>
                    <input className="btn primary minimum ml-1 mr-1" type="submit" value="Guardar" />
                </div>
            </fieldset>
        </form>
    )
}