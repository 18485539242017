import React, { useState } from 'react';
import { EditProduct } from './editProduct'

export function DetailsProducts(props) {

    return (
        <div className="wrapper_details_product padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Productos</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id == 0 ? "Asignar producto" : "Editar comisión"}</h6>
                    </div>
                </div>
            </div>

            <EditProduct id={props.match.params.id}/>
        </div>
    );
}   
