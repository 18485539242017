import React, { useState, useEffect } from "react";
import { backendService } from "./../../services/backend.service";
import swal from "sweetalert";
import { userProfileService } from "../../services/backoffice/userProfile.service"
import { userService } from "../../services/user.service";
import { Pending } from "./pending";
import { useForm } from "react-hook-form";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import "./profile.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function Profile() {
  const trigger = undefined;
  const [disabled, setDisabled] = useState(true);
  const [profile, setProfileInfo] = useState();
  const [changeImage, setChangeImage] = useState(false);
  const [btnImage, setBtnImage] = useState("fa fa-edit");
  const [referenceList, setReferenceList] = useState([]);
  const [originValues, setOriginValues] = useState({ nickname: "" });
  const [inputClass, setInputClass] = useState(
    "form-control-plaintext text-primary"
  );
  const [placeHolderText, setPlaceHolderText] = useState("");
  const { register, handleSubmit } = useForm();
  const companyId = useSelector((state) => state.userSettings.companyId);

  useEffect(() => {
    getProfileInfo();
    getReferenceByUser();
    
  }, [trigger]);

  const getProfileInfo = () => {
    backendService.userProfile().then((data) => {
      setOriginValues({ nickname: data.nickname ?? "" });
      setProfileInfo(data);
    });
  };

  const getReferenceByUser =()=> {
    userProfileService.getReferenceByUser().then((data)=> {
      const groupReference = groupBy(data, 'providerName');
      setReferenceList(groupReference);
    });
  }

  const deleteReference = (referenceId) => {
    swal({
        title: "¿Estas seguro?",
        text: "¿Desea eliminar la referencia?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancelar", "Si, eliminar"]
    })
        .then((willDelete) => {
        
            if (willDelete) {
                userProfileService.deleteReference(referenceId)
                    .then(function () {
                      swal({ text: "La referencia se eliminó correctamente", icon: "success" })
                      getReferenceByUser();
                    });
            }
        });
}


  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {}); 
  };


  const handleChange = (event) => {
    setChangeImage(event.target.files.length > 0);
    if (event.target.files.length > 0)
      setProfileInfo({
        ...profile,
        urlProfileImage: URL.createObjectURL(event.target.files[0]),
      });
  };

  const handleInputChange = (event) => {
    setProfileInfo({ ...profile, nickname: event.target.value });
  };

  const onSubmit = (data, e) => {
    data.Mobile = data.Mobile == undefined ? null : data.Mobile;
    data.Preferences = data.Preferences == undefined ? null : data.Preferences;

    userService.editProfile(data, companyId).then((result) => {
      if (result) {
        setChangeImage(false);
        setOriginValues({ nickname: profile.nickname ?? "" });
      } else {
        setProfileInfo({ ...profile, nickname: originValues.nickname });
      }

      setDisabled(true);
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
    });
  };

  const handleDisabled = () => {
    setDisabled(!disabled);
    if (disabled) {
      setBtnImage("fa fa-ban");
      setInputClass("form-control");
      setPlaceHolderText("Ingrese un sobre nombre");
    } else {
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
      setProfileInfo({ ...profile, nickname: originValues.nickname });
    }
  };

  const [asideShow, setAsideshow] = useState("");
  const activeAside = () => {
    setAsideshow(asideShow === "" ? " is-show" : "");
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <section className="wrapper_profile padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium">Mi Perfil</h2>
        </div>
      </div>

      <div className="content_profile">
        <div className="box_main_info_profile">
          <span className="btn_ellipsis" onClick={activeAside}>
            <i className="fas fa-ellipsis-v"></i>
          </span>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="box_user_info">
              <div className="main_info">
                <div className="box_one">
                  <div className="box_avatar_user">
                    <span className="avatar">
                      <img
                        src={profile && profile.urlProfileImage}
                        alt="NameUser"
                      />
                    </span>
                  </div>
                  <div>
                    <label
                      for="file-upload"
                      className="custom-file-upload icons"
                    >
                      <i className="fas fa-undo"></i>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="ProfileImage"
                      accept="image/*"
                      onChange={handleChange}
                      ref={register}
                    />
                    {changeImage ? (
                      <button type="submit" className="icons ico_save">
                        <i className="fas fa-check"></i>
                      </button>
                    ) : null}
                  </div>

                  <div>
                    <h5 className="text fnt_medium mb-1">
                      {" "}
                      {profile && profile.middleName}{" "}
                      {profile && profile.lastName}{" "}
                      {profile && profile.firstName}
                    </h5>
                    <h6 className="text-muted">{profile && profile.jobRole}</h6>
                  </div>
                </div>
                <div className="box_two">
                  <div>
                    <h6 className="text fnt_medium mb-0">Perfil</h6>
                    <h6 className="text-muted">
                      {profile && profile.aspNetRoleName}
                    </h6>
                  </div>
                  <div>
                    <h6 className="text fnt_medium mb-0">
                      Fecha de nacimiento
                    </h6>
                    <h6 className="text-muted">
                      <Moment locale="es" format="DD [de] MMMM  YYYY">
                        {profile && profile.birthday}
                      </Moment>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="second_info">
                {/*<div>
                                    <h6 className="text fnt_medium">
                                        <small>ANTIGÜEDAD</small>{" "}
                                    </h6>
                                    <h6 className="text-muted">{profile && profile.seniority}</h6>
                                </div>
                                <div>
                                    <h6 className="text fnt_medium">
                                        <small>DEPARTAMENTO</small>{" "}
                                    </h6>
                                    <h6 className="text-muted">{profile && profile.deparment}</h6>
                                </div>
                                <div>
                                    <h6 className="text fnt_medium">
                                        <small>Sucursal</small>{" "}
                                    </h6>
                                    <h6 className="text-muted">
                                        {profile && profile.branchOfficeName}
                                    </h6>
                                </div>
                                <div>
                                    <h6 className="text fnt_medium">
                                        <small>NO. EMPLEADO</small>{" "}
                                    </h6>
                                    <h6 className="text fnt_medium c_primary">
                                        {profile && profile.employeeNo}
                                    </h6>
                                </div>*/}
              </div>
            </div>

            <div className="box_details_account card shadows">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Detalles de la cuenta
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Mis Referencias
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5 className="text fnt_medium dwr_ttls_details mb-4">
                        <i className="far fa-user-circle"></i> Detalle de la
                        cuenta
                        <span className="btn_edit" onClick={handleDisabled}>
                          <i className={btnImage}></i>
                        </span>
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <label for="staticEmail" className="form-label m-0">
                        Email
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext text-primary"
                        id="staticEmail"
                        value={profile && profile.email}
                      />
                    </div>
                    <div className="col-md-6">
                      <label for="inputNickname" className="form-label m-0">
                        Nickname
                      </label>
                      <input
                        type="text"
                        className={inputClass}
                        id="nickname"
                        name="nickname"
                        value={profile && profile.nickname}
                        placeholder={placeHolderText}
                        onChange={handleInputChange}
                        ref={register}
                        disabled={disabled ? "disabled" : ""}
                      />
                    </div>
                    <div className="col-md-12 mb-4 mt-1 text-center">
                      {!disabled ? (
                        <>
                          <hr />
                          <button type="submit" className="btn primary minimum">
                            Guardar
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div>
                  <fieldset className="field_group">
                  <legend className='mb-0'>Referencias guardadas</legend>
                  {Object.keys(referenceList).length !== 0 ? 
                  <div>
                    {Object.keys(referenceList).map(cat => (
                    <div className="row">
                    <div className="col-sm-6 mb-4">
                      <h5 className="mb-0">
                        {cat}
                      </h5>
                      { referenceList && referenceList[cat].map(ref => (
                      <ul className="dwr_info_save list-group list-group-flush">
                      <li className="list-group-item ">
                        <p className='m-0'><b>{ref.keyName}</b></p>
                        <p className='m-0'>{ref.value}</p>
                        <span className="badge bg-primary rounded-pill" onClick={()=> deleteReference(ref.userProfileInputValueId)}>
                          <i className="far fa-trash-alt"></i>
                        </span>
                      </li>
                    </ul>
                      ))}

                    </div>
                  </div>
                  )
                  )}
                  </div>
                  : 
                    <div className="row">
                    <div className="col-sm-6 mb-4">
                      <h5 className="mb-0">
                        No existen referencias guardadas para este usuario
                      </h5>
                    </div>
                    </div>
                  }
                    </fieldset> 
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </form>
        </div>
        <Pending
          profile={profile}
          asideShow={asideShow}
          allClose={activeAside}
        />
      </div>
    </section>
  );
}
