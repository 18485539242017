import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './toolDiscount.scss'

import AsidePurchase from '../../components/asidePurchase/asidePurchase'
import { useSelector, useDispatch } from "react-redux";
import { setCartQuantity } from '../../store/actions';

export function ToolDiscount( { userProfileBalance }) {
    const dispatch = useDispatch();

    const cartQuantity = useSelector(state => state.cartQuantity);
    const roleName = localStorage.getItem('roleName');
    const [activeAside, setStateAside] = useState('');
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        dispatch(setCartQuantity());
    });

    const AsidePurchaseBox = () => {
        setStateAside(activeAside === '' ? ' is-show' : '')

        if (activeAside === '') {
            setCart(JSON.parse(localStorage["cartKuimby"]));
            setTotal(JSON.parse(localStorage["cartTotalKuimby"]));
        }
    }

    //User balance
    const countrySetting = useSelector(state => state.countrySetting);


    return <Fragment>
        <div className="wrapper_tool_discount">
            <div className="item_link_op br trans">
                <span><strong>{countrySetting.currency}{(Number(userProfileBalance.creditBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</strong></span>
                <span>Tu saldo a crédito</span>
            </div>
            <div className="item_link_op br trans">
                <span><strong>{countrySetting.currency}{(Number(userProfileBalance.prepaidBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</strong></span>
                <span>Tu saldo de pre-pago</span>
            </div>
            <Link className="item_link_op shadows trans" to="/discount">
                <span><i className="fas fa-th"></i></span>
                <span>Tiendas</span>
            </Link>
            {roleName == 'Administrador' && <Link className="item_link_op shadows trans" to="/company-shopping-history">
                <span><i className="fas fa-building"></i></span>
                <span>Reportes</span>
            </Link>}
            <Link className="item_link_op shadows trans" to="/shopping-history">
                <span><i className="fas fa-clipboard-list"></i></span>
                <span>Mis compras</span>
            </Link>
            <Link className="item_link_op shadows trans" onClick={AsidePurchaseBox}>
                <span><i className="fas fa-shopping-cart"></i></span>
                <span>Carrito <span>({cartQuantity})</span></span>
            </Link>
        </div>
        <AsidePurchase
            showBalancePay={(userProfileBalance.prepaidBalance + userProfileBalance.creditBalance) >= total}
            activeAside={activeAside}
            onCloseClick={() => setStateAside('')}
            cart={cart}
            total={total}
            setCart={setCart}
            setTotal={setTotal}
        />

    </Fragment>
}