import React, { useState, useEffect } from 'react';
import Slider from "react-slick"
import D_N from "../../assets/imgs/RV-03.jpg";
import './sliderHome.scss'

import { useCompanyHoliday } from '../../services/companyHoliday.service';

export function SliderHome() {
    const [sliderItems, setSliderItems] = useState([]);

    useEffect(() => {
        getCompanyHolidays();
    }, []);

    async function getCompanyHolidays() {
        useCompanyHoliday.getCompanyHolidayFront(1).then((result) => {
            if (result) {
                var temp = [];
                result.map((ch) => {
                    temp.push({
                        key: ch.companyHolidayId.toString(),
                        file: ch.imagePath,
                        description: ch.description
                    });
                });
                setSliderItems(temp);
            }
        });
    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        adaptiveHeight: true
    };
    return (
        <div className="wrapper_slide_home">
            {sliderItems && sliderItems.length > 0 ?
                <Slider {...settings}>
                    {
                        sliderItems.map(item =>
                            <div key={item.key}>
                                <img className="item_img_slider" src={item.file} alt={item.description} />
                            </div>)
                    }
                </Slider>
                : <img className="item_img_slider" src={D_N} alt="Red Companies" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />}
        </div>
    )
}
