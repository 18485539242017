import { callApi, callApiFile } from './service'

export const ecommerceService = {
    getProviders,
    getProviderProducts,
    getPaymentMethods,
    payOrder,
    getPurchaseOrder,
    getPurchaseOrderDetail,
    downloadSaleAsPDF, 
    payOrderExternal,
    downloadSaleAsCSV,
    getPurchaseOrdersFromCompany,
    downloadSalesAsCSV
};

async function getProviders() {
    return await callApi('/Ecommerce/Providers', 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getProviderProducts(providerId) {
    return await callApi('/Ecommerce/ProviderProducts?providerId=' + providerId, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return { CompanyProducts: [] };
        })
        .catch(error => {
            console.log('error', error);
            return { CompanyProducts: [] };
        });
}

async function getPaymentMethods() {
    return await callApi('/Ecommerce/PaymentMethods', 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return [];
        });
}

async function payOrder(model) {
    return await callApi('/Ecommerce/PayOrder', 'POST', model)
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return undefined;
        })
        .catch(error => {
            console.log('error', error);
            return undefined;
        });
}

async function payOrderExternal(model) {
    return await callApi('/Ecommerce/PayOrderExternal', 'POST', model)
        .then(result => {
                return result;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
}


async function getPurchaseOrder() {
    return await callApi('/Ecommerce/PurchaseOrders', 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return [];
        });
}

async function getPurchaseOrdersFromCompany(startDate, endDate) {
    return await callApi(`/Ecommerce/PurchaseOrdersCompany?startDate=${startDate}&endDate=${endDate}`, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return [];
        });
}

async function getPurchaseOrderDetail(id) {
    return await callApi('/Ecommerce/PurchaseOrderDetail?id=' + id, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return {};
        })
        .catch(error => {
            console.log('error', error);
            return {};
        });
}

async function downloadSaleAsPDF(id) {
    return await callApiFile(`/Ecommerce/DownloadSale?orderId=${id}&fileType=PDF` , 'GET')
        .then(function (blob) {
            if (blob.size !== 0) {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(blob, "order_" + id + ".pdf");
            }
        })
        .catch(error => {
            console.log('error', error);
            return {};
        });
}
async function downloadSaleAsCSV(id) {
    return await callApiFile(`/Ecommerce/DownloadSale?orderId=${id}&fileType=CSV` , 'GET')
        .then(function (blob) {
            if (blob.size !== 0) {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(blob, "order_" + id + ".csv");
            }
        })
        .catch(error => {
            console.log('error', error);
            return {};
        });
}

async function downloadSalesAsCSV(startDate, endDate) {
    return await callApiFile(`/Ecommerce/DownloadSales?startDate=${startDate}&endDate=${endDate}` , 'GET')
        .then(function (blob) {
            if (blob.size !== 0 && blob.type !== 'text/plain') {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(blob, "orders_" + startDate + "-" + endDate + ".csv");
            }
        })
        .catch(error => {
            console.log('error', error);
            return {};
        });
}