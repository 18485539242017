import React, { useState } from 'react';
import { EditDiscount } from './editDiscount'

export function DetailsDiscount(props) {

    return (
        <div className="wrapper_details_product padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Descuentos</h2>
                        <h6 className="mb-0 text-muted">Editar descuento</h6>
                    </div>
                </div>
            </div>

            <EditDiscount productId={props.match.params.productId} providerId={props.match.params.providerId}/>
        </div>
    );
}   
