export const setCountrySetting = (countrySetting) => {
    return {
        type: "Set_CountrySetting",
        countrySetting
    }
}

export const setCartQuantity = () => {
    return {
        type: "Set_CartQuantity"
    }
}

export const toggleBlocking = (status) => {
    return {
        type: "Toggle_Blocking", 
        payload: status
    }
}

export const setUserSettings = (userSettings) => {
    return {
        type: "Set_UserSettings",
        userSettings
    }
}