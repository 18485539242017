import React, { useState } from 'react';
import { EditBalance } from './editBalance'

export function DetailsBalance(props) {

    return (
        <div className="wrapper_details_balance padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Saldos</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.status == 1 ? "Solicitar " : "Asignar "}Saldo</h6>
                    </div>
                </div>
            </div>

            <EditBalance status={props.match.params.status} />
        </div>
    );
}   
