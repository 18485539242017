import { callApi } from '../service'

export const tradingmethodService = {
    getAll
};

async function getAll() {
    return callApi('/BackOffice/TradingMethod', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}