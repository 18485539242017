import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { AdminDeparment } from './adminDeparment'
import { AdminCompanyEventType } from './adminCompanyEventType'
import { BranchOffice } from './branchOffice/branchOffice'
import { AdminJobRoles } from './jobRoles/adminJobRole'
import { Notice } from './notice/notice'
import { CompanyCustomize } from './companyCustomize/companyCustomize'
import { useSelector } from 'react-redux';

import './control.scss'

export function Control() {
	const [activeTab, setActiveTab] = useState('1');
	const companyId = useSelector(state => state.userSettings.companyId)
	console.log("companyId: ",companyId)
	const toggle = tab => {
		
		if (activeTab !== tab) setActiveTab(tab);
	}

	return (
		<section className="wrapper_control padd">
			<div className="mb-4 divtop ">
				<div className="box_ttl_opt no_reverse">
					<h2 className="mb-0 text fnt_medium">Control</h2>
				</div>
			</div>

			<Nav tabs>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '1' })}
						onClick={() => { toggle('1'); }}
					>
						Departamentos
          </NavLink>
				</NavItem>
				{/*<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '4' })}
						onClick={() => { toggle('4'); }}
					>
						Tipos de evento
          </NavLink>
				</NavItem>*/}
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '5' })}
						onClick={() => { toggle('5'); }}
					>
						Sucursales
          </NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '6' })}
						onClick={() => { toggle('6'); }}
					>
						Puestos
          </NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '7' })}
						onClick={() => { toggle('7'); }}
					>
						Avisos
          </NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '8' })}
						onClick={() => { toggle('8'); }}
					>
						Personalización
          </NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<div className="card_tabs">
						<AdminDeparment />
					</div>
				</TabPane>
				{/*<TabPane tabId="4">
					<div className="card_tabs">
						<AdminCompanyEventType />
					</div>
				</TabPane>*/}
				<TabPane tabId="5">
					<div className="card_tabs">
						<BranchOffice />
					</div>
				</TabPane>
				<TabPane tabId="6">
					<div className="card_tabs">
						<AdminJobRoles />
					</div>
				</TabPane>
				<TabPane tabId="7">
					<div className="card_tabs">
						<Notice />
					</div>
				</TabPane>
				<TabPane tabId="8">
					<div className="card_tabs">
						<CompanyCustomize CompanyId={companyId}  />
					</div>
				</TabPane>
			</TabContent>
		</section>
	)
}