import { callApi } from './service'

export const eventService = {
    createEvent,
    updateEvent,
    getEvent,
    deleteEvent
};


async function createEvent(params) {
    return callApi('/backoffice/Event', 'POST', params);
}

async function updateEvent(params) {
    return callApi('/backoffice/Event', 'PUT', params);
}

async function getEvent() {
    return callApi('/backoffice/Event', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deleteEvent(params) {
    return callApi('/backoffice/Event', 'DELETE', params);
}