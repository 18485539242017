import React from 'react';
import IcoUser from '../../assets/imgs/iconos/ico_user.png'
import { userService } from '../../services/user.service';
import { useForm } from 'react-hook-form';

export function RecoverPassword() {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        userService.forgotPassword(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="sec_recover_pass">
            <h2 className=" text fnt_black c_primary">Recuperar contraseña</h2>
            <h6 className="text fnt_medium">Ingresa tu correo electrónico y te enviaremos las instrucciones sobre cómo restablecerla.</h6>
            <div className="form-group mt-4">
                <span className="box_input_icon">
                    <input type="email" className="form-control" id="user" name="Email" aria-describedby="emailHelp" placeholder="Correo electrónico" ref={register} />
                    <span className="ico_input">
                        <img src={IcoUser} alt="User" />
                    </span>
                </span>
            </div>
            <div className="mt-4 mb-3">
                <button type="submit" className="btn primary">Recuperar contraseña</button>
            </div>
        </form>
    )
}