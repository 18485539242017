import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { Tooltip } from "reactstrap";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import "./header.scss";

//import LogoCompany from '../../assets/imgs/R_C.png'
import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import LogoCompany from "../../assets/imgs/R_C.png";

export function Header({ MyFun, activeBtn }) {
    const history = useHistory();
    function pad2(n) {
        return n < 10 ? "0" + n : n;
    }
    const date = new Date();
    const settings = useSelector((state) => state.userSettings);
    const companyLogo =
        settings.companyLogo != null
            ? settings.companyLogo +
            "?v=" +
            date.getFullYear().toString() +
            pad2(date.getMonth() + 1) +
            pad2(date.getDate()) +
            pad2(date.getHours()) +
            pad2(date.getMinutes()) +
            pad2(date.getSeconds())
            : LogoCompany;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const togglex = () => setTooltipOpen(!tooltipOpen);

    const logout = () => {
        var style = document.getElementsByTagName("style");
        document
            .getElementsByTagName("head")[0]
            .removeChild(style[style.length - 1]);
        userService.logout().then(() => {
            history.push("/");
        });
    };

    const [notificationInfo, setNotificationInfo] = useState({
        notificationInfoData: [],
    });

    //useEffect(() => {
    //  getNotificationInfo();
    //});

    async function getNotificationInfo() {
        backendService.GetNotificationData().then((result) => {
            if (result) {
                setNotificationInfo({ notificationInfoData: result });
            }
        });
    }

    //Company balance
    const countrySetting = useSelector(state => state.countrySetting);
    const [companyBalance, setCompanyBalance] = useState(null);

    useEffect(() => {
        if (settings.roleName == "Admin_Intermediario" || settings.roleName == "Admin_Empresa")
            getCompanyBalance();
    }, [settings.roleName]);

    const getCompanyBalance = () => {
        backendService.getCompanyBalance()
            .then(result => setCompanyBalance(result));
    }

    return (
        <header className="wrapper_header trans">
            <div className="box_brand">
                <span className={`btn_menu ${activeBtn}`} onClick={MyFun}>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <NavLink to="/home">
                    <img src={companyLogo} alt="Brand" />
                </NavLink>
            </div>
            <div className="content_header">
                {/*<div className="box_search_input">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                        </div>
                        <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Buscar" />
                    </div>
                </div>*/}
                <div className="box_options">
                    {companyBalance &&
                        <div className="wrapper_tool_balance">
                            <div className="item_balance shadows trans" >
                                <span><strong>{countrySetting.currency}{(Number(companyBalance.creditBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></span>
                                <span>Saldo de compañía a credito</span>
                            </div>
                            <div className="item_balance shadows trans">
                                <span><strong>{countrySetting.currency}{(Number(companyBalance.prepaidBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></span>
                                <span>Saldo de compañía de pre pago</span>
                            </div>
                        </div>}
                    {/*<Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle
                            className="btn_notifications"
                            tag="span"
                            aria-expanded={dropdownOpen}
                        >
                            <span className="icon_bell">
                                <i className="far fa-bell"></i>
                                <span className="bullet">
                                    {" "}
                                    {notificationInfo.notificationInfoData.length}{" "}
                                </span>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {notificationInfo.notificationInfoData.length > 0 ? (
                                notificationInfo.notificationInfoData.map((item) => (
                                    <a
                                        className="item_notification"
                                        href={item.linkNotification}
                                        onClick={toggle}
                                    >
                                        <span>
                                            {item.companyEventTypeNotificationMessage}{" "}
                                            {item.notificationMessage}{" "}
                                        </span>
                                    </a>
                                ))
                            ) : (
                                    <a className="item_notification" href="#" onClick={toggle}>
                                        <span>No hay nuevas notificaciones</span>
                                    </a>
                                )}
                        </DropdownMenu>
                    </Dropdown>*/}
                    <span className="item_options" onClick={logout}>
                        <span id="TooltipClose">
                            <i className="fas fa-power-off"></i>
                            <Tooltip
                                placement="bottom"
                                isOpen={tooltipOpen}
                                target="TooltipClose"
                                toggle={togglex}
                            >
                                Cerrar sesión
                            </Tooltip>
                        </span>
                    </span>
                </div>
            </div>
        </header>
    );
}
