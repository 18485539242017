import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { attendeeService } from '../../services/attendee.service';

const SearchAttendee = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        attendeeService.deleteAttendee(data).then((result) => { getAttende(); });
        event.stopPropagation();
    };

    async function getAttende() {
        attendeeService.getAttendee()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAttende();
    }, []);

    const refreshDataGrid = () => {
        getAttende();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <Table>
            <thead>
                <tr>
                    <th>Event</th>
                    <th>BranchOffice</th>
                    <th>Department</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                {dataGrid.map(item => (    
                    <tr key={item.atendeeId}>
                        <td>{item.eventId}</td>
                        <td>{item.branchOfficeId}</td>
                        <td>{item.departmentId}</td>
                        <td>{item.active ? 'Active' : 'Inactive'}</td>
                        {item.active ? <td><button type="button" onClick={(event) => handleDelete(event, item)}>Delete</button> </td> : null}
                    </tr>
                ))}

            </tbody>
        </Table>
    );

});

export default SearchAttendee;
