import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const userProfileService = {
    getAll,
    getById,
    getCatalogs,
    create,
    edit,
    uploadMigrationFile,
    getSavedReference,
    getReferenceByUser,
    deleteReference
};

async function getAll(pageIndex, pageCount, filterBy, filter, orderBy, ascending) {
    return callApi(`/BackOffice/UserProfile?pageIndex=${pageIndex}&pageCount=${pageCount}&filterBy=${filterBy}&filter=${filter}&orderBy=${orderBy}&ascending=${ascending}`, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return { totalPages: 0, list: [] };
            }
        });
}

async function getById(id) {
    return callApi('/Account/Get?userId=' + id, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function getCatalogs() {
    return callApi('/BackOffice/UserProfileCatalogs', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function create(param) {
    return callApi('/Account/Create', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Usuario guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/users");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}


async function edit(param) {
    debugger
    return callApi('/Account/Edit', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Usuario guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/users");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function uploadMigrationFile(companyId, file) {
    const formData = new FormData();
    formData.append("file", file);
    return callApiFileStorage('/Account/UploadMigrationFile?companyId=' + companyId, 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "El proceso de carga de usuarios inicio correctamente", icon: "success" }).then(() => {
                    window.location.href = "/users";
                })
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}
async function getSavedReference(providerInputValueId){
    return callApi('/Backend/UserProfileInputValue/' + providerInputValueId, 'GET').
    then((result) => {
        if(result.responseCode = "00"){
            return result.data;
        }
        else{
            return{};
        }
    });
}
async function getReferenceByUser(referenceList){
    return callApi('/Backend/UserProfileInputValue/', 'GET').
    then((result) => {
        if(result.responseCode = "00"){
            
            return result.data;
        }
        else{
            return{};
        }
    });
}
async function deleteReference(referenceId){
    return callApi('/BackEnd/UserProfileInputValue/' + referenceId, 'DELETE').
    then((result) => {
        if(result.responseCode = "00"){
            
            return result.data;
        }
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}