import React, { useState, useEffect } from "react";
import { HubItemProvider } from "../../components/hubItemProvider/hubItemProvider";
import { ToolDiscount } from "../../components/toolDiscount/toolDiscount";
import { backendService } from "../../services/backend.service";

import { Nav, NavItem, NavLink } from "reactstrap";
import "./discount.scss";
import { ecommerceService } from "../../services/ecommerce.service";

export const Discount = () => {
  const [currentCategory, setCurrentCategory] = useState({});
  const [providerCategory, setProviderCategory] = useState([]);
  const [userProfileBalance, setUserProfileBalance] = useState({
    creditBalance: 0,
    prepaidBalance: 0,
  });

  const getUserProfileBalance = () => {
    backendService
      .getUserProfileBalance()
      .then((result) => setUserProfileBalance(result));
  };

  useEffect(() => {
    getUserProfileBalance();
  }, []);

  useEffect(() => {
    ecommerceService.getProviders().then((result) => {
      console.log("providerCategory:", result);
      setProviderCategory(result);
    });
  }, []);

  return (
    <section className="wrapper_discount padd">
      <div className="mb-2 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium ">Tienda</h2>
          <ToolDiscount userProfileBalance={userProfileBalance} />
        </div>

        <h4 className="ttls fw600">
          <span className="ico_shop">
            <i className="fas fa-shopping-bag"></i>
          </span>
          <span className="pl-2 pr-2">
            Diversión y entretenimiento sin límite
          </span>
        </h4>

        <p className="font-italic pp">
          Entra a tus marcas favoritas y sorpréndete{" "}
        </p>
      </div>

      <Nav tabs className="drawer_tabs_discount">
        {providerCategory.map((item) => (
          <NavItem key={item.providerCategoryId}>
            <NavLink
              className={{
                active:
                  currentCategory &&
                  currentCategory.providerCategoryId ===
                    item.providerCategoryId,
              }}
              onClick={() => setCurrentCategory(item)}
            >
              <div className="item_tab_category">
                <div className="ico_tab_category">
                  <img src={item.icon} alt="Red Voucher" />
                </div>
                <h6 className="name_tab_category">{item.description}</h6>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <HubItemProvider
        category={currentCategory}
        userProfileBalance={userProfileBalance}
      />
    </section>
  );
};
