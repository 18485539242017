import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert"

import useInputForm from '../../components/CustonHooks/FormHook';
import { paymenttypeService } from '../../services/backoffice/paymenttype.service';
import { tradingmethodService } from '../../services/backoffice/tradingmethod.service';

import { companyService } from '../../services/control/company.service';
import { balanceService } from '../../services/control/balance.service';

export function EditBalance(props) {
    const [paymentType, setPaymentType] = useState([]);
    const [tradingMethod, setTradingMethod] = useState([]);
    const [companyUserProfCatalog, setCompanyUserProfCatalog] = useState([]);
    const [balance, setBalance] = useState({
        tradingMethodId: 0,
        userReceiveId: 0,
        balanceAmount: 0.0,
        folio: "",
        voucherImage: "",
        paymentTypeId: 0,
        active: true,
        balanceStatus: props.status
    });

    const onSave = () => {
        inputs.paymentTypeId = parseInt(inputs.paymentTypeId);
        inputs.tradingMethodId = parseInt(inputs.tradingMethodId);
        inputs.userReceiveId = parseInt(inputs.userReceiveId);
        inputs.balanceAmount = parseFloat(inputs.balanceAmount);
        inputs.balanceStatus = parseInt(inputs.balanceStatus)
        if (inputs.balanceStatus == 2) {
            balanceService.alertPassword("Asignación").then(result => {
                if (result) {
                    inputs.password = result;
                    balanceService.create(inputs);
                }
                else swal("No se ingreso contraseña", "Favor de Ingresar su contraseña para realizar acción!", "error");
            });
        } else balanceService.create(inputs);
    };

    const handleChangeCompanyUsersCatalog = (event) => {
        setInputs({ ...inputs, userReceiveId: parseInt(event.target.value) });
    }

    const handleChangePaymentType = (event) => {
        setInputs({ ...inputs, paymentTypeId: event.target.value });
    }

    const handleChangeTradingMethod = (event) => {
        setInputs({ ...inputs, tradingMethodId: event.target.value });
    }

    const getUserProfCompanyCatalog = () => {
        companyService.getCompanyUsersCatalog()
            .then((result) => { setCompanyUserProfCatalog(result) });
    }

    const getPaymentType = () => {
        paymenttypeService.getAll()
            .then((result) => setPaymentType(result));
    }

    const getTradingMethod = () => {
        tradingmethodService.getAll()
            .then((result) => setTradingMethod(result));
    }

    useEffect(() => {
        getUserProfCompanyCatalog();
        getPaymentType();
        getTradingMethod();
    }, [props.balance]);

    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, balance);

    return (
        <div className="wrapper_details_balance card shadows p-4">
            <form onSubmit={handleSubmit}>
                <fieldset>
                    <legend className="text fnt_medium">{props.status == 1 ? "Solicitar " : "Asignar "}Saldo</legend>
                    {props.status == 2 &&
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="form-group">
                                    <label for="">Usuario</label>
                                    <select className="form-control" id="userReceiveId" name="userReceiveId" onChange={handleChangeCompanyUsersCatalog} value={inputs.userReceiveId} required>
                                        <option key="0" value="">Selecciona una opción</option>
                                        {
                                            companyUserProfCatalog.map(item => <option key={item.userProfileId} value={item.userProfileId}>{item.name}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="">Método de Pago</label>
                                <select className="form-control" id="paymentTypeId" name="paymentTypeId" onChange={handleChangePaymentType} value={inputs.paymentTypeId} required>
                                    <option key="0" value="">Selecciona una opción</option>
                                    {
                                        paymentType.map(item => <option key={item.paymentTypeId} value={item.paymentTypeId}>{item.paymentTypeName}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="tradingMethodId">Método de Negociación</label>
                                <select className="form-control" id="tradingMethodId" name="tradingMethodId" onChange={handleChangeTradingMethod} value={inputs.tradingMethodId} required>
                                    <option key="0" value="">Selecciona una opción</option>
                                    {
                                        tradingMethod.map(item => <option key={item.tradingMethodId} value={item.tradingMethodId}>{item.tradingMethodName}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label for="balanceAmount">Monto</label>
                                <input type="number" name="balanceAmount" id="balanceAmount" onChange={handleInputChange} value={inputs.balanceAmount} className="form-control" min=".01" step=".01" required />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label for="folio">Folio</label>
                                <input type="text" name="folio" id="folio" onChange={handleInputChange} value={inputs.folio} className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label for="voucherImage">Comprobante</label>
                                <input type="file" accept="image/*" name="voucherImage" id="voucherImage" onChange={handleInputFileChange} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/balance" >Regresar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}