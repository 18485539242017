import React, { useRef, useState } from 'react';
import { Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from '../../components/CustonHooks/inputFormHook';
import { SelectCustom } from '../../components/Helpers/SelectCustom';
import SearchDeparment from './searchDeparment';
import { deparmentService } from '../../services/deparment.service';
import { useSelector } from 'react-redux';

export function AdminDeparment() {
	const companyId = useSelector(state => state.userSettings.companyId)

	const gridRef = useRef();
	let deparmentInit = { DepartmentId: 0, CompanyId: 0, DepartmentName: '' }
	const [showEdit, setShowEdit] = useState(false);
	const onSave = () => {
		let model = {
			DepartmentId: parseInt(inputs.DepartmentId), CompanyId: companyId, DepartmentName: inputs.DepartmentName
		}

		if (parseInt(inputs.DepartmentId) === 0) {
			deparmentService.createDeparment(model).then((result) => {
				gridRef.current.refreshDataGrid();

			});
		} else {
			deparmentService.updateDeparment(model).then((result) => {
				gridRef.current.refreshDataGrid();
			});
		}
		setInputs({});
		setShowEdit(false);

	};
	const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

	const handleOnCancel = () => {
		setInputs({});
		setShowEdit(false);
	}

	const handleOnNewDeparment = () => {
		setInputs(deparmentInit);
		setShowEdit(true);
	};

	const handleRowSelect = (gridItem) => {
		setShowEdit(true);
		setInputs({ DepartmentId: gridItem.departmentId, CompanyId: gridItem.companyId, DepartmentName: gridItem.departmentName });
	}

	return (
		<div>
			<div className="mb-4 mt-4 divtop ">
				<div className="box_ttl_opt no_reverse">
					<h3 className="mb-0">Lista de departamentos</h3>
					<div className="box_actions ">
						<button type="button" className="btn secundary mr-2" onClick={handleOnNewDeparment}><i className="fas fa-plus"></i> Agregar departamento</button>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-12">
					{showEdit ?
						<Form onSubmit={handleSubmit}>
							<fieldset className='mt-4 mb-5'>
								<legend className="text fnt_medium">{inputs.DepartmentId == 0 ? "Nuevo departamento" : "Editar departamento"}</legend>
								<div className="row">
									<div className="col-md-4">
										<FormGroup >
											<Label for="DepartmentName">Departamento</Label>
											<Input name="DepartmentName" placeholder="Department Name" onChange={handleInputChange} value={inputs.DepartmentName} required></Input>
										</FormGroup>
									</div>
								</div>

								<div>
									<button className="btn secundary minimum ml-1 mr-1" type="button" onClick={handleOnCancel}>Cancelar</button>
									<input className="btn primary minimum minimum ml-1 mr-1" type="submit" value="Guardar" />
								</div>
							</fieldset>
						</Form> : null}

					<SearchDeparment handleRowSelect={handleRowSelect} ref={gridRef} />

				</div>
			</div>

		</div>
	);
}