import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { eventTypeService } from '../../services/eventType.service';

const SearchEventType = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event,data) => {
        eventTypeService.deleteEventType(data).then((result) => { getEventTypes(); });
        event.stopPropagation();
    };

    async function getEventTypes() {
        eventTypeService.getEventType()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getEventTypes();
    }, []);

    const refreshDataGrid = () => {       
        getEventTypes();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <Table>
            <thead>
                <tr>
                    <th>Event Name</th>
                    <th>Description</th>
                    <th>Computed</th>
                    <th>Action Type</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                {dataGrid.map(item => (
                    <tr key={item.eventTypeId} onClick={() => props.handleRowSelect(item)}>
                        <td>{item.eventName}</td>
                        <td>{item.description}</td>
                        <td>{item.computed ? 'True' : 'False'}</td>
                        <td>{item.actionTypeId}</td>
                        <td>{item.active ? 'Active' : 'Inactive'}</td>
                        {item.active ? <td><button type="button" onClick={(event) => handleDelete(event, item)}>Delete</button> </td> : null}
                    </tr>
                ))}

            </tbody>
        </Table>
    );
});

export default SearchEventType;
