import { callApi } from './service'
import swal from "sweetalert"

export const deparmentService = {
    createDeparment,
    updateDeparment,
    getDeparment,
    deletetDeparment
};


async function createDeparment(params) {
    return callApi('/backoffice/Department', 'POST', params);
}

async function updateDeparment(params) {
    return callApi('/backoffice/Department', 'PUT', params);
}

async function getDeparment() {
    return callApi('/backoffice/Department', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deletetDeparment(params) {
    return callApi('/backoffice/Department', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El departamento se desactivo correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar el departamento", icon: "error" });
        }
    });
}

