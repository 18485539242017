import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert"

import useInputForm from '../../components/CustonHooks/FormHook';

import { companyProductService } from '../../services/backoffice/companyProduct.service';

export function EditDiscount(props) {
    // inputs
    const [company, setCompany] = useState([]);
    const [providers, setProviders] = useState([]);
    const [productsProvider, setProductsProvider] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(false);
    const [commission, setCommision] = useState({
        providerId: 0,
        productId: 0,
        companyId: 0,
        pecentageDiscountUser: 0,
        value: 0,
        price: 0
    });

    // submit form
    const onSave = () => {
        inputs.productId = parseInt(inputs.productId);
        inputs.providerId = parseInt(inputs.providerId);
        inputs.companyId = parseInt(inputs.companyId);
        inputs.pecentageDiscountUser = parseInt(inputs.pecentageDiscountUser);
        companyProductService.update(inputs);
    };

    // handleinputs
    const handleChangePercentageDiscount = (event) => {
        const commission = parseInt(event.target.value);
        setInputs({ ...inputs, pecentageDiscountUser: parseInt(event.target.value), price: (inputs.value * (100 - commission)) / 100 });
    }

    // ciclelife
    useEffect(() => {
        if (props.productId != 0) {
            companyProductService.getById(parseInt(props.productId), parseInt(props.providerId))
                .then((result) => { setInputs(result) });
        }
    }, [props.productId, props.providerId]);

    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, commission);

    return (
        <div className="wrapper_details_balance card shadows p-4">
            <form onSubmit={handleSubmit}>
                <fieldset>
                    <legend className="text fnt_medium">Editar Descuento</legend>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="providerId">Proveedor</label>
                                { props.productId == 0 ?
                                    null
                                    :
                                    <legend className="text fnt_medium">{inputs.providerName}</legend>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="productId">Producto</label>
                                { props.productId == 0 ?
                                    null
                                    :
                                    <legend className="text fnt_medium">{inputs.productName}</legend>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="nominalValue">Valor Facial/Precio</label>
                                <input type="number" name="nominalValue" id="nominalValue" className="form-control disable" min="1" step="1" value={inputs.value} disabled />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="pecentageDiscountUser">Descuento %</label>
                                <input type="number" name="pecentageDiscountUser" id="pecentageDiscountUser" className="form-control" min="0" max="100" value={inputs.pecentageDiscountUser} onChange={handleChangePercentageDiscount} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label for="price">Precio Final</label>
                                <input type="number" name="price" id="price" className="form-control disable" value={inputs.price} disabled />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/discount-user" >Regresar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}