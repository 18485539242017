import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const balanceService = {
    create,
    get,
    getById,
    getBalanceAll,
    edit,
    deleteCompany,
    alertPassword
};


async function create(data) {
    const formData = new FormData();
    if (data.balanceStatus == 2) formData.append("userReceiveId", data.userReceiveId);
    formData.append("tradingMethodId", data.tradingMethodId);
    formData.append("balanceAmount", data.balanceAmount);
    formData.append("folio", data.folio);
    formData.append("voucherImage", data.voucherImage);
    formData.append("paymentTypeId", data.paymentTypeId);
    formData.append("balanceStatusId", data.balanceStatus);
    formData.append("password", data.password);

    return await callApiFileStorage('/BackOffice/Balance', 'POST', formData, false)
        .then(result => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/balance");
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function get(status) {
    return await callApi('/BackOffice/Balance?status=' + status, 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/Balance/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getBalanceAll() {
    return callApi('/BackOffice/BalanceAll', 'GET').then((result) => {
        if (result.responseCode === '00')
            // console.log(result.data);
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function edit(data) {
    return callApi('/BackOffice/Balance', 'PUT', data)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: `Se ${data.balanceStatusId == 2 ? 'autorizo' : 'rechazo'} correctamente`, icon: "success" });
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function deleteCompany(id) {
    return callApi('/BackOffice/Balance/' + id, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "se desactivó correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la compañía", icon: "error" });
            return null;
        }
    });
}


async function alertPassword(action) {
    // validate password is correct
    return swal({
        title: `Confirmar ${action}`,
        text: "Para realizar la acción favor de confirmar con su contraseña.",
        icon: "warning",
        buttons: true,
        succesMode: true,
        content: {
            element: "input",
            attributes: {
                placeholder: "Escribe tu contraseña",
                type: "password",
            },
        },
        contetnType: "password",
    })
        .then((value) => value);
}


