import React, { useState, useEffect } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { UserActivity } from '../../components/userActivity/userActivity'

import { userProfileService } from '../../services/backoffice/userProfile.service'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import './users.scss'
import { useSelector } from 'react-redux';


export function UserDetails(props) {
	const companyId = useSelector(state => state.userSettings.companyId)
	const { register, handleSubmit } = useForm();
	const [catalogs, setCatalogs] = useState({ aspNetRolesCatalog: [], branchOfficeCatalog: [], departmentCatalog: [], jobRoleCatalog: [] });
	const [isNew, setIsNew] = useState(false);
	const [user, setUser] = useState({active : true});
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	useEffect(() => {
		userProfileService.getCatalogs()
			.then((result) => {
				setCatalogs(result);
				setIsNew(props.match.params.id == "new");
				if (!isNew) {
					getById(props.match.params.id);
				}				
			});

	}, [props.match.params.id]);

	const getById = (id) => {
		// debugger
		userProfileService.getById(id)
			.then((result) => {
				// debugger
				setUser(result);
			});
	}

	const handleActive = (e) => {
		setUser({ ...user, active: !user.active });
	}

	const onSubmit = (data, e) => {
		data.CompanyId = companyId;
		data.Notification = data.Notification == 'true';
		data.BranchOfficeId = parseInt(data.BranchOfficeId);
		data.DepartmentId = parseInt(data.DepartmentId);
		data.JobRoleId = parseInt(data.JobRoleId);
		data.Active = data.active;
		
		if (isNew)
			userProfileService.create(data);
		else
			userProfileService.edit(data);
	}

	const handleChange = (event) => {
		setUser({ ...user, [event.target.id]: event.target.value });
	}

	return (
		<section className="wrapper_users_details padd">
			<div className="mb-4 divtop">
				<div className="box_ttl_opt mb-1">
					<h2 className="mb-0 text fnt_medium">Usuario</h2>
				</div>
				<div className="box_ttl_opt">
					<h4 className="mb-0">{isNew ? "Usuario nuevo" : "Detalle del usuario"}</h4>
				</div>
			</div>

			<div>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === '1' })}
							onClick={() => { toggle('1'); }}
						>
							Información general
                    </NavLink>
					</NavItem>
					{/*<NavItem>
						<NavLink
							className={classnames({ active: activeTab === '2' })}
							onClick={() => { toggle('2'); }}
						>
							Actividad de usuario
                        </NavLink>
					</NavItem>*/}
				</Nav>

				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<div className="card_tabs">
							<form onSubmit={handleSubmit(onSubmit)}>
								<input type="hidden" value={user.aspNetUserId} name="UserId" ref={register} />
								 <input type="hidden" value={user.preferences} name="Preferences" ref={register} />
								<input type="hidden" value={user.nickname} name="Nickname" ref={register} />
								<input type="hidden" value={user.notification} name="Notification" ref={register} /> 
								<input type="hidden" value={user.urlProfileImage} name="UrlProfileImage" ref={register} />
								<fieldset className='mt-4 mb-5'>
									<legend className="text fnt_medium">Datos de la cuenta</legend>
									<div className="row">
										<div className="col-sm-6 col-lg-2 mb-2">
  											<label htmlFor="Active">Status</label>
   											<label className="control-checkbox">Usuario activo
    											<input type="checkbox" className="control-checkbox-input" id="active" name="active" checked={user.active} ref={register} onChange={handleActive} value={user.active}/>
    											<span className="control-checkbox-checkmark"></span>
  											</label>
										</div>											
										<div className="col-sm-6 col-lg-4">
											<div className="form-group">
												<label for="roleId">Perfíl</label>
												<select className="form-control" id="roleId" name="RoleId" value={user.roleId} onChange={handleChange} ref={register} >
													{
														catalogs.aspNetRolesCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
													}
												</select>
											</div>
										</div>
										<div className="col-sm-6 col-lg-4">
											<div className="form-group">
												<label for="email">Correo electrónico</label>
												<input type="email" className="form-control" id="email" name="Email" aria-describedby="emailHelp" placeholder="correo@miempresa.com" defaultValue={user.email} ref={register} />
											</div>
										</div>
										{
											isNew ?
												<div className="col-sm-6 col-lg-4">
													<div className="form-group">
														<label for="exampleInputPassword1">Password</label>
														<input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" name="Password" ref={register} />
													</div>
												</div>
												: ""
										}
									</div>
								</fieldset>

								<fieldset className='mb-5'>
									<legend className="text fnt_medium">Datos de usuario</legend>
									<div className="row">
										<div className="col-md-3">
											<div className="form-group">
												<label for="">No. Empleado</label>
												<input type="text" className="form-control" id="" placeholder="xxx-xxx" name="EmployeeNo" defaultValue={user.employeeNo} ref={register} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Nombre(s)</label>
												<input type="text" className="form-control" id="" placeholder="Nombre(s)" name="FirstName" defaultValue={user.firstName} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Apellido paterno</label>
												<input type="text" className="form-control" id="" placeholder="Apellido paterno" name="MiddleName" defaultValue={user.middleName} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Apellido materno</label>
												<input type="text" className="form-control" id="" placeholder="Apellido materno" name="LastName" defaultValue={user.lastName} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="gender">Género</label>
												<select className="form-control" id="gender" value={user.gender} name="Gender" onChange={handleChange} ref={register} >
													<option value="M">Masculino</option>
													<option value="F">Femenino</option>
												</select>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="birthday">Fecha de nacimiento</label>
												<input className="form-control" type="date" id="birthday" name="Birthday" value={moment(user.birthday).format('YYYY-MM-DD')} onChange={handleChange} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Tel. Personal</label>
												<input type="text" className="form-control" id="" placeholder="Tel. Personal" name="Mobile" defaultValue={user.mobile} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Tel. Oficina</label>
												<input type="text" className="form-control" id="" placeholder="Tel. Oficina" name="PhoneNumber" defaultValue={user.phoneNumber} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="">Ext.</label>
												<input type="text" className="form-control" id="" placeholder="Ext." name="Extension" defaultValue={user.extension} ref={register} />
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="hiringDate">Fecha de ingreso</label>
												<input className="form-control" type="date" id="hiringDate" name="HiringDate" value={moment(user.hiringDate).format('YYYY-MM-DD')} onChange={handleChange} ref={register} />
											</div>
										</div>
									</div>
									<hr />
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label for="branchOfficeId">Sucursal</label>
												<select className="form-control" id="branchOfficeId" name="BranchOfficeId" value={user.branchOfficeId} onChange={handleChange} ref={register} >
													{
														catalogs.branchOfficeCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
													}
												</select>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="departmentId">Departamento</label>
												<select className="form-control" id="departmentId" name="DepartmentId" value={user.departmentId} onChange={handleChange} ref={register} >
													{
														catalogs.departmentCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
													}
												</select>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<label for="jobRoleId">Puesto</label>
												<select className="form-control" id="jobRoleId" name="JobRoleId" value={user.jobRoleId} onChange={handleChange} ref={register} >
													{
														catalogs.jobRoleCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
													}
												</select>
											</div>
										</div>
									</div>
								</fieldset>
								<div className="text-right mb-4">
									<button type="submit" className="btn primary">Guardar</button>
								</div>
							</form>
						</div>
					</TabPane>
					{/*<TabPane tabId="2">
						<div className="card_tabs p-4">
							<UserActivity />
						</div>
					</TabPane>
					*/}
				</TabContent>
			</div>



		</section>
	)
}