import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { eventService } from '../../services/event.service';

const SearchEvent = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {    
        eventService.deleteEvent(data).then((result) => { getEvents(); });
        event.stopPropagation();
    };

    async function getEvents() {
        eventService.getEvent()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getEvents();
    }, []);

    const refreshDataGrid = () => {
        getEvents();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <Table>
            <thead>
                <tr>
                    <th>Company Event Id</th>
                    <th>Event Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                {dataGrid.map(item => (
                    <tr key={item.eventId} onClick={() => props.handleRowSelect(item)}>
                        <td>{item.companyEventTypeId}</td>
                        <td>{item.eventTitle}</td>
                        <td>{item.startDate}</td>
                        <td>{item.endDate}</td>
                        <td>{item.active ? 'Active' : 'Inactive'}</td>
                        {item.active ? <td><button type="button" onClick={(event) => handleDelete(event, item)}>Delete</button> </td> : null}
                    </tr>
                ))}

            </tbody>
        </Table>
    );
});

export default SearchEvent;
