import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { companyService } from '../../services/control/company.service';
import { useSelector } from "react-redux";
 import { companyPaymentMethodService } from "../../services/control/companyPaymentMethod.service";
export function SearchCompany(props) {
    const countrySetting = useSelector(state => state.countrySetting);
    const [companiesData, setCompaniesData] = useState({ companies: [] });

    const handleDelete = (data) => {
        companyService.deleteCompany(data).then((result) => { getCompanies(); });
    };

    const [paymentMethods, setPaymentMethods] = useState([]);
    useEffect(() => {
        getCompanies(props.statusValue);
    }, [props.statusValue]);

    async function getCompanies(stat) {
        let new_stat
        if(stat === undefined)
            new_stat = true;
        else
            new_stat = stat;

        companyService.get(new_stat).then((result) => {
            if (result) {
                setCompaniesData({ companies: result });
            }
        });
    }
    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">

                <thead>
                    <tr>
                        <th className="text fnt_medium">ID
					</th>
                        <th className="text fnt_medium min_width">Nombre
					</th>
                        <th className="text fnt_medium">Nivel
					</th>
                        <th className="text fnt_medium">Dominio
					</th>
                        <th className="text fnt_medium">Método de pago
					</th>
                        <th className="text fnt_medium">Saldo a credito
					</th>
                        <th className="text fnt_medium">Saldo de pre pago
					</th>
                        <th className="text fnt_medium">Estatus
					</th>
                        <th className="text fnt_medium">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {companiesData.companies.map(company => (
                        <tr key={company.companyId}>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.companyId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.companyName}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.levelName}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.urlRedVoucher}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}> {company.paymentMethodName ?? "Sin método de pago" }</Link>
                            </td>
                            <td>
                                {countrySetting.currency}{(Number(company.creditBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                            </td>
                            <td>
                                {countrySetting.currency}{(Number(company.prepaidBalance)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                            </td>
                            <td>{company.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                {
                                    company.active ?
                                        <span className="icon_tables" onClick={() => handleDelete(company.companyId)}>
                                            <i className="far fa-trash-alt"></i>
                                        </span>
                                        : ""
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}