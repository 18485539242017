import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./hubItemProvider.scss";
import ProviderModal from "../providerModal/providerModal";

import $ from 'jquery'
export class HubItemProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      currentProvider: {},
    };
  }


  componentWillMount() {
    // localStorage cart
    if (localStorage.getItem("cartKuimby") == undefined)
      localStorage.setItem("cartKuimby", "[]");
    if (localStorage.getItem("cartTotalKuimby") == undefined)
      localStorage.setItem("cartTotalKuimby", 0);
    if (localStorage.getItem("cartQuantityKuimby") == undefined)
      localStorage.setItem("cartQuantityKuimby", 0);

    
  }

  


  setModal = (modal, item) => {
    this.setState({ modal });
    if (item) this.setState({ currentProvider: item });
  };


  actionProvider = (item) => {
    if (item.providerInputValueDto.providerInputValueId > 0) {
      return { onClick: () => { $('body').addClass('modal_is_open'); this.setModal(true, item) }};
    } else return { to: "/provider/" + item.id };
  };


  render() {
    const { providerDTO } = this.props.category;

    return (
      <div className="box_container_hub" noClick>
        {providerDTO &&
          providerDTO.map((item) => {
            return (
              <Link
                {...this.actionProvider(item)}
                className="item_provaider_hub"
                key={item.id}
                params={this.props.userProfileBalance}
              >
                <p className="item_content">
                  <p className="box_p_img">
                    <img src={item.providerLogo} alt="Proveedor red Voucher 2.0" />
                  </p>
                </p>
              </Link>
            );
          })}
        <ProviderModal
          modal={this.state.modal}
          setModal={this.setModal}
          provider={this.state.currentProvider}
          userProfileBalance={this.props.userProfileBalance}
        />
        <div className='boxnegro'></div>
      </div>
    );
  }
}
