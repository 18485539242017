import React, { useEffect, useState } from 'react';
import { balanceService } from '../../services/control/balance.service';
import swal from "sweetalert"
import { useSelector } from "react-redux";

import './balance.scss'

export function SearchBalance(props) {
    const countrySetting = useSelector(state => state.countrySetting);
    const [balanceData, setBalanceData] = useState({ balances: [] });

    const getBalanceAll = () => {
        balanceService.getBalanceAll().then((result) => {
            if (result) {
                setBalanceData({ balances: result });
            }
        });
    }

    useEffect(() => {
        getBalanceAll();
    }, []);


    const onClickAction = (id, status) => {
        balanceService.alertPassword(status == 2 ? "Autorización" : "Rechazo").then(result => {
            if (result) {
                balanceService.edit({ balanceId: id, balanceStatusId: status, password: result })
                    .then(result => { if (result) getBalanceAll() });
            }
            else swal("No se ingreso contraseña", "Favor de Ingresar su contraseña para realizar acción!", "error");
        });
    }

    const downloadVoucherIMage = (data) => {
        fetch(data)
            .then(response => response.blob())
            .then(function(myBlob) {
                const url = window.URL.createObjectURL(new Blob([myBlob]));
                const link = document.createElement('a');
                var extension = data.split('.').pop(); 
                link.href = url;
                link.setAttribute('download', `Comprobante.${extension}`);
                document.body.appendChild(link);
                link.click();
            });
        
    }

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium">ID Traspaso</th>
                        <th className="text fnt_medium">Método de Pago</th>
                        <th className="text fnt_medium">Método de Negociación</th>
                        <th className="text fnt_medium">Saldo</th>
                        <th className="text fnt_medium">Intermediario / Compañia</th>
                        <th className="text fnt_medium">Usuario Final</th>
                        <th className="text fnt_medium">Folio</th>
                        <th className="text fnt_medium">Fecha de Creación</th>
                        <th className="text fnt_medium">Comprobante</th>
                        <th className="text fnt_medium">Estatus</th>
                        {/*<th className="text fnt_medium"></th>*/}
                    </tr>
                </thead>
                <tbody>
                    {
                        balanceData.balances.map(balance => (
                            <tr key={balance.balanceId}>
                                <td>{balance.balanceId}</td>
                                <td>{balance.paymentTypeName}</td>
                                <td>{balance.tradingMethodName}</td>
                                <td>{countrySetting.currency}{(Number(balance.balanceAmount)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td>{(balance.companyReceiveId === null) ? "" : balance.companyReceiveName}</td>
                                <td>{(balance.userReceiveId === null) ? "" : balance.userReceiveName}</td>
                                <td>{(balance.folio === null) ? "" : balance.folio}</td>
                                <td>{balance.createDate}</td>
                                <td>{
                                    (balance.voucherImage === null) 
                                    ? "" 
                                    : <button onClick={() => downloadVoucherIMage(balance.voucherImage)} className="btn btn-sm btn-info">
                                        Descargar <i class="fas fa-download"></i>
                                    </button>
                                }
                                </td>
                                <td>
                                    {balance.balanceStatusId == 1 && balance.editable
                                        ?
                                        <span>
                                            <span className="option text-success" onClick={() => onClickAction(balance.balanceId, 2)} tooltip=''>
                                                <i class="fas fa-check-circle">Autorizar</i>
                                            </span>
                                            <br />
                                            <span className="option text-danger" onClick={() => onClickAction(balance.balanceId, 3)}>
                                                <i class="fas fa-times-circle">Rechazar</i>
                                            </span>
                                        </span>
                                        : <span className="icon_tables">
                                            {balance.balanceStatusName}
                                        </span>
                                    }
                                </td>
                                {/*<td>
                                    <span className="icon_tables">
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td>*/}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div >
    )
}