import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import useInputForm from "../../components/CustonHooks/FormHook";

import { companyService } from "../../services/control/company.service";
import { productService } from "../../services/backoffice/product.service";

export function EditProduct(props) {
  // inputs
  const [company, setCompany] = useState([]);
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [commission, setCommision] = useState({
    saleChannelId: 0,
    providerId: 0,
    productId: 0,
    companyId: 0,
    commission: 0,
    value: 0,
    price: 0,
  });

  // submit form
  const onSave = () => {
    if (props.id == 0) {
      inputs.saleChannelId = parseInt(inputs.saleChannelId);
      inputs.providerId = parseInt(inputs.providerId);
      inputs.companyId = parseInt(inputs.companyId);
      productService.create(inputs);
    } else {
      inputs.id = props.id;
      productService.update(inputs);
    }
  };

  const handleChangeSaleChannel = (event) => {
    setInputs({ ...inputs, saleChannelId: event.target.value });
    if (event.target.value != "") getProviders(event.target.value);
    else setProviders([]);
  };

  const handleChangeProviders = (event) => {
    setInputs({ ...inputs, providerId: event.target.value });
    if (event.target.value != "") {
      getProducts(inputs.saleChannelId, event.target.value);
    } else {
        setProducts([]);
    }
  };

  const handleChangeProducts = (event) => {
    const value = parseInt(event.target.value.split("-")[1]);
    setInputs({
      ...inputs,
      productId: event.target.value,
      value: value,
      price: (value * (100 - inputs.commission)) / 100,
    });
  };

  const handleChangeCompany = (event) => {
    setInputs({ ...inputs, companyId: event.target.value });
  };

  const handleChangeCommission = (event) => {
    const commission = parseFloat(event.target.value);
    setInputs({
      ...inputs,
      commission: parseFloat(event.target.value),
      price: (inputs.value * (100 - commission)) / 100,
    });
  };

  const getCompanies = () => {
    companyService.getCompanyCatalog().then((result) => {
      setCompany(result);
    });
  };

  const getProducts = (providerId, saleChannelId) => {
    productService.getProducts(providerId, saleChannelId).then((result) => {
      setProducts(result);
    });
  };

  const getProviders = (saleChannelId) => {
    productService.getProviders(saleChannelId).then((result) => {
      setProviders(result);
    });
  };

  // ciclelife
  useEffect(() => {
    if (props.id == 0) {
      getCompanies();
    } else {
      productService.getById(props.id).then((result) => {
        setInputs(result);
      });
    }
  }, [props.id]);

  const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
    onSave,
    commission
  );

  return (
    <div className="wrapper_details_balance card shadows p-4">
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend className="text fnt_medium">
            {props.id == 0 ? "Asignar producto" : "Editar comisión"}
          </legend>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="saleChannelId">Canal de venta</label>
                {props.id == 0 ? (
                  <select
                    className="form-control"
                    id="saleChannelId"
                    name="saleChannelId"
                    onChange={handleChangeSaleChannel}
                    value={inputs.saleChannelId}
                    required>
                    <option key="0" value="">
                      Selecciona una opción
                    </option>
                    <option key="4" value="4">
                      Ecommerce
                    </option>
                    <option key="7" value="7">
                      API
                    </option>
                  </select>
                ) : (
                  <legend className="text fnt_medium">
                    {inputs.saleChannelName}
                  </legend>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="providerId">Proveedor</label>
                {props.id == 0 ? (
                  <select
                    className="form-control"
                    id="providerId"
                    name="providerId"
                    onChange={handleChangeProviders}
                    value={inputs.id}
                    required>
                    <option key="0" value="">
                      Selecciona una opción
                    </option>
                    {providers.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <legend className="text fnt_medium">
                    {inputs.providerName}
                  </legend>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="productId">Producto</label>
                {props.id == 0 ? (
                  <select
                    className="form-control"
                    id="productId"
                    name="productId"
                    onChange={handleChangeProducts}
                    value={inputs.productId}
                    required>
                    <option key="0" value="">
                      Selecciona una opción
                    </option>
                    {products
                      ? products.map((item) => (
                          <option
                            key={item.productId}
                            value={item.productId + "-" + item.value}>
                            {item.productName}
                          </option>
                        ))
                      : null}
                  </select>
                ) : (
                  <legend className="text fnt_medium">
                    {inputs.productName}
                  </legend>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="companyId">Intermediario / Compañia</label>
                {props.id == 0 ? (
                  <select
                    className="form-control"
                    id="companyId"
                    name="companyId"
                    onChange={handleChangeCompany}
                    value={inputs.companyId}
                    required>
                    <option key="0" value="">
                      Selecciona una opción
                    </option>
                    {company.map((item) => (
                      <option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <legend className="text fnt_medium">
                    {inputs.companyName}
                  </legend>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="value">Valor facial/Precio</label>
                <input
                  type="number"
                  name="value"
                  id="value"
                  className="form-control disable"
                  min="1"
                  step="1"
                  value={inputs.value}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="commission">Descuento %</label>
                <input
                  type="number"
                  name="commission"
                  id="commission"
                  className="form-control"
                  min="0"
                  max="100"
                  step="0.01"
                  value={inputs.commission}
                  onChange={handleChangeCommission}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <label for="price">Precio Final</label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="form-control disable"
                  value={inputs.price}
                  disabled
                />
              </div>
            </div>
          </div>
          <div>
            <Link className="btn secundary minimum ml-1 mr-1" to="/products">
              Regresar
            </Link>
            <button className="btn primary minimum" type="submit">
              Guardar
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
