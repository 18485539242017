import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert"
import { useSelector } from "react-redux";
import { companyProductService } from '../../services/backoffice/companyProduct.service';

import './discount.scss'

export function SearchDiscount(props) {
    const [data, setData] = useState([]);

    const getAllCompanyProduct = () => {
        companyProductService.getAll().then((result) => {
            if (result) {
                setData(result);
            }
        });
    }

    useEffect(() => {
        getAllCompanyProduct();
    }, []);

    const countrySetting = useSelector(state => state.countrySetting);

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium">Proveedor </th>
                        <th className="text fnt_medium">Producto</th>
                        <th className="text fnt_medium">Valor Facial/Precio</th>
                        <th className="text fnt_medium">Descuento %</th>
                        <th className="text fnt_medium">Precio Final</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map(item => (
                            <tr key={item.productId}>
                                <td>{item.providerName}</td>
                                <td><Link className="link_data" to={"/details-discount-user/" + item.productId + "/" + item.providerId}>{item.productName}</Link></td>
                                <td>{countrySetting.currency}{(Number(item.value)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</td>
                                <td><Link className="link_data" to={"/details-discount-user/" + item.productId + "/" + item.providerId}>{(item.pecentageDiscountUser != null) ? item.pecentageDiscountUser : "0"} %</Link></td>
                                <td>
                                    {countrySetting.currency}{(Number(item.price)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div >
    )
}