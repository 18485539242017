import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchBalance } from './searchBalance';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from "react-redux";

import './balance.scss'

export function Balance() {
    const roleName = useSelector((state) => state.userSettings.roleName);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <section className="wrapper_survey_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Saldos</h2>
                    </div>
                    <div className="box_actions">
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="btn primary" caret>
                                <i className="fas fa-money-bill-alt"></i> Saldo
                                </DropdownToggle>
                            <DropdownMenu right>
                                {roleName != "Administrador" && <Fragment> <DropdownItem>
                                    <Link to="/details-balance/1">
                                        <i className="fas fa-money-check"></i> Solicitar
                                        </Link>
                                </DropdownItem>
                                    <DropdownItem divider /></Fragment>}

                                <DropdownItem>
                                    <Link to="/details-balance/2">
                                        <i className="fas fa-money-check-alt"></i> Asignar
                                        </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
            <div>
                <SearchBalance />
            </div>

        </section>
    )
}