import React from 'react';
import { helperService } from '../../services/helper.service';

export function SelectCustom(props) {
  const [items, setItems] = React.useState([]);
  React.useEffect(() => {
    getCatalog();
  }, [props.method]);

  async function getCatalog() {
    helperService.getGenericCatalog("/" + (props.controller ?? "backoffice") + "/" + props.method)
      .then((result) => {
        if (result) {
          setItems(result);
        }
        else
          setItems([]);
      });
  }

  return (
    <select className="form-control" name={props.selectName} onChange={props.handleOnChange}
      selectvalue={props.selectValue} value={props.selectValue} required={props.required} disabled={props.disabled} >
      <option value="">Selecciona una opción</option>
      {items.map(item => (
        <option key={item[props.valueField]} value={item[props.valueField]}>
          {item[props.textField]}
        </option>
      ))}
      {props.extraOption ? <option value={props.extraOption.valueField}>{props.extraOption.textField}</option> : null}
    </select>
  );
}
