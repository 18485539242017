import React, { useState } from "react";

import LogoCompany from "../../assets/imgs/R_C.png";
import IcoPass from "../../assets/imgs/iconos/ico_pass.png";
import { userService } from "../../services/user.service";
import { useForm } from "react-hook-form";

export function ResetPassword(props) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    userService.resetPassword(data);
  };

  const [showPass, setShowPass] = useState("password");
  const [showIco, setShowIco] = useState("fa-eye-slash");

  const setInputPass = () => {
    setShowPass(showPass === "password" ? "text" : "password");
    setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
  };

  return (
    <div className="wrapper_login">
      <div className="content_login">
        <div className="box_msj">
          <div>
            <h1 className="text fnt_black ttls_black">Bienvenidos</h1>
            <p className="paragraph parag_big mb-0">
              Únete a este gran equipo e interactúa con nosotros
            </p>
          </div>
        </div>
        <div className="box_form">
          <div className="box_inputs">
            <div className="box_logo_company_login">
              <img src={LogoCompany} alt="Name Company" />
            </div>
            <h2 className="ttl_form text fnt_black ttls_black">
              Ingresa tu nueva contraseña
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                id="code"
                name="Code"
                value={props.location.search.split("code=")[1]}
                ref={register}
              />
              <input
                type="hidden"
                id="email"
                name="Email"
                value={props.location.search.split("email=")[1].split("&")[0]}
                ref={register}
              />

              <div className="form-group">
                <span className="box_input_icon">
                  <input
                    type={showPass}
                    className="form-control"
                    id="password"
                    name="Password"
                    placeholder="Nueva contraseña"
                    ref={register}
                  />
                  <span className="ico_show_pass" onClick={setInputPass}>
                    <i className={`fas ${showIco}`}></i>
                  </span>
                  <span className="ico_input">
                    <img src={IcoPass} alt="Password" />
                  </span>
                </span>
              </div>
              <div className="form-group">
                <span className="box_input_icon">
                  <input
                    type={showPass}
                    className="form-control"
                    id="new_password"
                    name="ConfirmPassword"
                    placeholder="Confirmar nueva contraseña"
                    ref={register}
                  />
                  <span className="ico_show_pass" onClick={setInputPass}>
                    <i className={`fas ${showIco}`}></i>
                  </span>
                  <span className="ico_input">
                    <img src={IcoPass} alt="Password" />
                  </span>
                </span>
              </div>
              <div className="mt-4 mb-3">
                <button type="submit" className="btn primary">
                  Guardar contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
