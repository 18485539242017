import { callApi } from './service'

export const eventTypeService = {
    editEventType,
    getEventType,
    deleteEventType
};


async function editEventType(params) {
    return callApi('/backoffice/EventType', 'POST', params);
}

async function getEventType() {
    return callApi('/backoffice/EventType', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deleteEventType(params) {
    return callApi('/backoffice/EventType', 'DELETE', params);
}