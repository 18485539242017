import { callApi } from '../service'
import swal from "sweetalert"

export const companyProductService = {
    update,
    getAll,
    getById
};

async function update(data) {
    return await callApi('/BackOffice/DiscountUser', 'PUT', data)
        .then(result => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/discount-user");
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function getAll() {
    return callApi('/BackOffice/DiscountUser', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}

async function getById(productId, providerId) {
    return callApi('/BackOffice/DiscountUser/' + productId + '/' + providerId, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}