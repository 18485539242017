import { callApi } from '../service'
import swal from "sweetalert"

export const companyService = {
    create,
    get,
    getById,
    getByCompanyId,
    getByDomain,
    getCompanyCatalog,
    getCompanyUsersCatalog,
    edit,
    deleteCompany,
    getRVOL
};

async function create(data) {
    return callApi('/BackOffice/Company', 'POST', data)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/AdminCompany")
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function get(status) {
    return await callApi('/BackOffice/Company?status=' + status, 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/Company/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getByCompanyId(id) {
    return callApi('/BackEnd/Company/GetByCompanyId/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getByDomain(domain) {
    return callApi('/BackOffice/Company/GetByDomain/' + domain, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getCompanyCatalog() {
    return callApi('/BackOffice/CompanyCatalog/', 'GET').then((result) => {
        if (result.responseCode === '00')
            // console.log(result.data)
            return result.data;
        else {
            alert(result.responseMessage);
            return [];
        }
    });
}


async function getCompanyUsersCatalog() {
    return callApi('/BackOffice/UserProfileCatalog/', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                // console.log(result.data)
                return result.data;
            } else {
                return {};
            }
        });
}


async function edit(data) {
    debugger
    return callApi('/BackOffice/Company', 'PUT', data)
        .then((result) => {
            debugger
            if (result.responseCode === "00") {
                swal({ text: "Se actualizó correctamente", icon: "success" }).then(() => window.location.href = "/AdminCompany")
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function deleteCompany(id) {
    return callApi('/BackOffice/Company/' + id, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "se desactivó correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la compañía", icon: "error" });
            return null;
        }
    });
}

async function getRVOL() {
    return await callApi('/Ecommerce/Company', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}


