import React, { Fragment, useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import { backendService } from '../../services/backend.service'
import { useParams, Link } from 'react-router-dom'
import Moment from 'react-moment'
import AnswerMessage from './answerMessage'
import { MessageAttachment } from './messageAttachment'

export function ReadMessage(props) {
  const [message, setMessage] = useState()
  const [files, setFiles] = useState()
  const { id } = useParams()
  const [isAnswer, setIsAnswer] = useState(false)
  const [profile, setProfileInfo] = useState()

  useEffect(() => {
    messageService.getMessageById(id).then((data) => {
      setMessage(data.data);
      setFiles(data.files);
    })
  }, []);

  useEffect(() => {
    backendService.userProfile()
      .then((data) => setProfileInfo(data))
  }, [])

  const isSender = () => {
    let myName = ''
    let from = ''
    if (profile && message) {
      myName = profile.firstName + profile.middleName + profile.lastName
      from = message.messageUserFrom.middleName + message.messageUserFrom.lastName + message.messageUserFrom.firstName
    }
    return profile && message && myName === from
  }


  return (
    <section className="wrapper_read_message padd">
      <div className="mb-4">
        <Link className="back_go" to="/messages">
          <i className="fas fa-chevron-left"></i>  Atrás
        </Link>
      </div>
      {message &&
        <div>
          <fieldset className="xxxxxxx mt-4">
            <legend>
              <div>
                <span><i className="far fa-envelope-open"></i></span> {message.message.subject}
              </div>
            </legend>
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="content_info_msg">
                  <div className="box_avatar_user">
                    <span className="avatar">
                      <img src={message.messageUserFrom.urlProfileImage} class="contactphoto" onerror="this.src = 'skins/elastic/images/contactpic.svg'; this.onerror = null" />
                    </span>
                  </div>
                  <div className="box_info_msg">
                    <h6 className="text fnt_medium mb-0"> {
                    //message.messageUserFrom.nickname == null || message.messageUserFrom.nickname == '' ? 
                    message.messageUserFrom.middleName + " " + message.messageUserFrom.lastName + " " + message.messageUserFrom.firstName
                    //: message.messageUserFrom.nickname
                    }</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="box_data_msg">

                  <p className="mb-0"><span><i className="fas fa-clock"></i></span> <Moment locale="es" format="DD/MM/YYYY h:mm:ss a">{message.message.createDate}</Moment></p>
                  {!isSender() &&
                    <span className="ml-3 link_send" onClick={() => setIsAnswer(!isAnswer)}><i className="fas fa-share"></i> Responder</span>
                  }
                </div>
              </div>
            </div>
            <div className="box_adjunts">
              {files &&
                <Fragment>
                  <hr></hr>
                Archivos:
                <MessageAttachment files={files} isDeleted={false} />
                </Fragment>
              }
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: message.message.messageText }} />
            </div>
          </fieldset>
          <div >
            {isAnswer &&
              <AnswerMessage
                originMessageId={message.message.messageId}
                messageUserFrom={message.message.userProfileId}
                messageUserTo={message.message.userProfileFrom}
                subject={message.message.subject}
                setShow={setIsAnswer}
              />

            }
          </div>
          {message.messagesList &&
            <Fragment>
              {message.messagesList.map(answerd =>
                <ParentMessage key={answerd.messageId} messageId={answerd.messageId} />
              )}
            </Fragment>
          }
        </div>
      }
    </section>
  )
}

export default function ParentMessage(props) {
  const [message, setMessage] = useState();
  const [files, setFiles] = useState();

  useEffect(() => {
    messageService.getMessageById(props.messageId).then((data) => {
      setMessage(data.data);
      setFiles(data.files);
    })
  }, []);

  return (
    <Fragment>
      {message &&
        <div>
          <fieldset >
            <legend>
              {!message.message.originMessageId &&
                <div>
                  <span><i className="far fa-envelope-open"></i></span> {message.message.subject}
                </div>
              }
            </legend>
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="content_info_msg">
                  <div className="box_avatar_user">
                    <span className="avatar">
                      <img src={message.messageUserFrom.urlProfileImage} class="contactphoto" onerror="this.src = 'skins/elastic/images/contactpic.svg'; this.onerror = null" />
                    </span>
                  </div>
                  <div className="box_info_msg">
                    <h6 className="text fnt_medium mb-0"> {
                                        //message.messageUserFrom.nickname == null || message.messageUserFrom.nickname == '' ? 
                                        message.messageUserFrom.middleName + " " + message.messageUserFrom.lastName + " " + message.messageUserFrom.firstName
                                        //: message.messageUserFrom.nickname
                    // message.messageUserFrom.firstName + " " + message.messageUserFrom.middleName + " " + message.messageUserFrom.lastName
                    }
                    
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="box_data_msg">
                  <p className="mb-0"><span><i className="fas fa-clock"></i></span> <Moment locale="es" format="DD/MM/YYYY h:mm:ss a">{message.message.createDate}</Moment></p>
                </div>
              </div>
            </div>
            <hr />
            <div className="box_adjunts">
              {files &&
                <div>
                  <MessageAttachment files={files} isDeleted={false}></MessageAttachment>
                </div>
              }
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: message.message.messageText }} />
            </div>

          </fieldset>

        </div>
      }
    </Fragment>
  )
}