import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    Redirect,
    useLocation
} from 'react-router-dom'
import {
    LayoutFull,
    LayoutLess
} from './components/layouts'

import * as Pages from './pages'
import * as Admin from './backoffice'
import * as Control from './control'
import ReactGA from 'react-ga';

export function App(props) {
    const location = useLocation();

    useEffect(() => {
        //Google Analytics()
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
        ReactGA.plugin.require('displayfeatures');
        ReactGA.pageview(location.pathname);
    }, []);

    useEffect(() => {
        ReactGA.pageview(location.pathname)
    }, [location.pathname]);

    return (
        
            <Switch>
                <Route exact path='/login' render={x => (<LayoutLess children={<Pages.Login {...x} />} />)} />
                <Route exact path='/reset-password' render={x => (<LayoutLess children={<Pages.ResetPassword {...x} />} />)} />
                <Route exact path='/home' render={x => (<LayoutFull children={<Pages.Home />} />)} />
                <Route exact path='/messages' render={x => (<LayoutFull children={<Pages.Messages />} />)} />
                <Route exact path='/messages/:id' render={x => (<LayoutFull children={< Pages.ReadMessage />} />)} />
                <Route exact path='/discount' render={x => (<LayoutFull children={<Pages.Discount />} />)} />
                <Route exact path="/provider/:id" render={x => (<LayoutFull children={<Pages.Provider {...x} />} />)} />
                <Route exact path='/oxxopay' render={x => (<LayoutFull children={<Pages.OxxoPay />} />)} />
                <Route exact path='/order-details/:id' render={x => (<LayoutFull children={<Pages.OrderDetail {...x} />} />)} />
                <Route exact path='/canceled' render={x => (<LayoutFull children={<Pages.PaymentCanceled />} />)} />
                <Route exact path='/shopping-history' render={x => (<LayoutFull children={<Pages.ShoppingHistory />} />)} />
                <Route exact path='/company-shopping-history' render={x => (<LayoutFull children={<Pages.CompanyShoppingHistory />} />)} />
                <Route exact path='/profile' render={x => (<LayoutFull children={<Pages.Profile />} />)} />
                <Route exact path='/help' render={x => (<LayoutFull children={<Pages.Help />} />)} />
                <Route exact path='/users' render={x => (<LayoutFull children={<Admin.Users />} />)} />
                <Route exact path='/users-details/:id' render={x => (<LayoutFull children={<Admin.UserDetails {...x} />} />)} />
                <Route exact path='/bulk-load' render={x => (<LayoutFull children={<Admin.BulkLoad />} />)} />
                <Route exact path='/control' render={x => (<LayoutFull children={<Admin.Control />} />)} />
                <Route exact path='/CompanyEventType' render={x => (<LayoutFull children={<Pages.AdminCompanyEventType />} />)} />
                <Route exact path='/Notice' render={x => (<LayoutFull children={<Admin.Notice />} />)} />

                {/* == OPERACIONES == */}
                <Route exact path='/AdminCompany' render={x => (<LayoutFull children={<Control.AdminCompany {...x} />} />)} />
                <Route exact path='/details-company/:id' render={x => (<LayoutFull children={<Control.DetailsCompany  {...x} />} />)} />

                {/* == Saldos == */}
                <Route exact path='/balance' render={x => (<LayoutFull children={<Control.Balance {...x} />} />)} />
                <Route exact path='/details-balance/:status' render={x => (<LayoutFull children={<Control.DetailsBalance {...x} />} />)} />

                {/* == Asignar Productos a Inter/Company == */}
                <Route exact path='/products' render={x => (<LayoutFull children={<Control.Products {...x} />} />)} />
                <Route exact path='/details-product/:id' render={x => (<LayoutFull children={<Control.DetailsProducts {...x} />} />)} />

                {/* == Descuentos a usuarios == */}
                <Route exact path='/discount-user' render={x => (<LayoutFull children={<Control.Discount {...x} />} />)} />
                <Route exact path='/details-discount-user/:productId/:providerId' render={x => (<LayoutFull children={<Control.DetailsDiscount {...x} />} />)} />

                <Redirect from='/' to='/home' />

            </Switch>

    )
}