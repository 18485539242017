import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import './blockScreen.scss';

export const BlockScreen = () => {
    const blocking = useSelector(state => state.blocking);
    return (
      <Modal show={blocking} keyboard={false} className="wrapper_block">
        <div className="box_content_block">
          <Spinner name="ball-pulse-rise" color="#e94242" />
        </div>
      </Modal>
    );
}