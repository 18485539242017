import React, { useState, useEffect } from 'react'
import { MenuAside } from './menuAside/menuAside'
import { Header } from './header/header'
import { backendService } from '../services/backend.service';
import { useDispatch } from 'react-redux';
import { setUserSettings } from '../store/actions'
import { BlockScreen } from './blockScreen/blockScreen';
import { Preferences } from '../pages/profile/preferences';
import { InstallPWA } from './pwa/installPWA';

export function LayoutFull(props) {
    const dispatch = useDispatch();
    const [activeMenu, setStateMenu] = useState('')
    const MenuPurchaseBox = () => {
        setStateMenu(activeMenu === '' ? ' is-show' : '')
    }

    const [activeBtn, setStateBtn] = useState('')
    const BtnSet = () => {
        setStateBtn(activeBtn === '' ? ' visible_menu' : '')
    }

    const MyFun = () => {
        BtnSet();
        MenuPurchaseBox();
    }
    const setCompanyCustom = (companyCustom) => {
        var userPreferences = companyCustom.preferences!= null? JSON.parse(companyCustom.preferences):null
        var style = document.createElement('style')
        style.type = 'text/css';
        style.innerHTML = `:root {--primary_color: ${userPreferences && userPreferences.PrimaryColor != null ? userPreferences.PrimaryColor: companyCustom.primaryColor};--secondary_color: ${companyCustom.secondaryColor};}`;
        document.getElementsByTagName('head')[0].appendChild(style);
    }
    useEffect(() => {
        backendService.userSettings()
            .then((result) => {
                dispatch(setUserSettings(result))
                setCompanyCustom(result);
            });
    
    }, []);

    return (
        <section className="wrapper_main">
            <BlockScreen />
            <MenuAside
                activeMenu={activeMenu}
                closeAll={() => MyFun()}
            />

            <div className="section_main trans">
                <Header MyFun={() => MyFun()} activeBtn={activeBtn} history={props.history} />
                {props.children}
            </div>
            <InstallPWA/>
        </section>
    )
}

export function LayoutOperations(props) {
    const dispatch = useDispatch();
    const [activeMenu, setStateMenu] = useState('')
    const MenuPurchaseBox = () => {
        setStateMenu(activeMenu === '' ? ' is-show' : '')
    }

    const [activeBtn, setStateBtn] = useState('')
    const BtnSet = () => {
        setStateBtn(activeBtn === '' ? ' visible_menu' : '')
    }

    const MyFun = () => {
        BtnSet();
        MenuPurchaseBox();
    }

    useEffect(() => {
        backendService.userSettings()
            .then((result) => dispatch(setUserSettings(result)));
    }, []);

    return (
        <section className="wrapper_main">
            <BlockScreen />
            <MenuAside
                activeMenu={activeMenu}
                closeAll={() => MyFun()}
            />

            <div className="section_main trans">
                <Header MyFun={() => MyFun()} activeBtn={activeBtn} history={props.history} />
                {props.children}
            </div>
            <InstallPWA />

        </section>
    )
}

export function LayoutLess(props) {
    return (
        <section>
            <BlockScreen />
            {props.children}
            <InstallPWA />

        </section>
    )
}