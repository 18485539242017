import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { SliderHome } from "../../components/sliderHome/sliderHome";
import { BoxUserProfile } from "../../components/boxUserProfile/boxUserProfile";
import { backendService } from "../../services/backend.service";
import { messageService } from "../../services/message.service";

// ==ICONOS==
import IcoMessage from "../../assets/imgs/iconos/ico_message.png";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";
import IcoDiscount from "../../assets/imgs/iconos/ico_discount.png";
import IcoRewards from "../../assets/imgs/iconos/ico_rewards.png";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function Home() {
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const trigger = undefined;
  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    backendService.widgets().then((data) => {
      setWidgets(data);
    });

    messageService
      .getMessage(0, "desc", "pending")
      .then((data) => setMessages(data));
  }, [trigger]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <section className="wrapper_home padd">
      <div className="row row_data_home">
        <Link to="/messages" className="col-6 col-md-6 col-lg-3 mb-3">
          <div className="item_data_home shadows">
            <div>
              <h2 className="mb-0 text fnt_medium">{messages.length}</h2>
              <h5 className="mb-0 text fnt_medium">Mensajes</h5>
            </div>
            <div className="drop_icon">
              <img src={IcoMessage} alt="Mensaje" />
            </div>
          </div>
        </Link>
        <Link to="/discount" className="col-6 col-md-6 col-lg-3 mb-3">
          <div className="item_data_home shadows">
            <div className="box_company_home">
              <Slider {...settings}>
                {widgets.providerWidget &&
                  widgets.providerWidget.map((item) => (
                    <div>
                      <img
                        src={item.logoCrls + "?v=" + Date.now()}
                        alt="Provider"
                      />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="drop_icon">
              <img src={IcoDiscount} alt="Company" />
            </div>
          </div>
        </Link>
      </div>
      <div className="row row_home mb-3">
        <div className="col-sm-12 col-lg-8 mb-1">
          <SliderHome />
        </div>
        <div className="col-sm-12 col-lg-4 mb-1 ">
          <BoxUserProfile />
        </div>
      </div>
    </section>
  );
}
