import React, { useState, useEffect, Fragment } from 'react';
import './boxUserProfile.scss'
import { Link } from 'react-router-dom'
import { backendService } from './../../services/backend.service'


export function BoxUserProfile() {
    const [profile, setProfileInfo] = useState()
    useEffect(() => {
        backendService.userProfile()
            .then((data) => setProfileInfo(data))
    }, [])

    return (
        <div className="wrapper_box_user_profile">
            <Link to="/profile" className="ico_tool">
                <i className="fas fa-cog"></i>
            </Link>
            <div>
                <div className="box_avatar_user">
                    <span className="avatar">
                        <img src={profile && profile.urlProfileImage} alt="NameUser" />
                    </span>
                </div>
            </div>
            <div className="box_info_user text-center">
                <h4 className="mb-0"> {profile && profile.middleName} {profile && profile.lastName} {profile && profile.firstName}</h4>
                <h7 className="mb-0">{profile && profile.nickname}</h7>
                <h6 className="mb-0">{profile && profile.jobRole}</h6>
            </div>
            {/*<div className="box_data_user">
        <div>
          <h6>No. Empleado</h6>
          <p>{profile && profile.employeeNo}</p>
        </div>
        <div>
          <h6>Dirección</h6>
          <p>{profile && profile.deparment}</p>
        </div>
        <div>
          <h6>Antigüedad</h6>
          <p> {profile && profile.seniority}</p>
        </div>
      </div>*/}
        </div>
    )
}