import React, { Component } from "react";
import { Link } from "react-router-dom";
import { cartHelper } from "../../helpers/cart.helper";
import { connect } from "react-redux";
import { setCartQuantity } from "../../store/actions";
import { ecommerceService } from "../../services/ecommerce.service";
import Moment from "react-moment";

import SuccessImg from "../../assets/imgs/ico_ticket.svg";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: "",
    };
  }

  componentWillMount() {
    cartHelper.clean();
    this.props.setCartQuantity();
    this.orderDetail(this.props.match.params.id);
  }

  orderDetail = (orderId) => {
    window.setTimeout(
      function () {
        ecommerceService.getPurchaseOrderDetail(orderId).then((result) => {
          this.setState({ orderDetail: result });
        });
      }.bind(this),
      2000
    );
  };

  render() {
    const { orderDetail } = this.state;
    return (
      <div className="wrapper_pay_successful">
        <div className="container">
          <div className="row align-items-center justify-content-md-center">
            <div className="col-md-10 col-lg-6 text-center">
              <div className="box_success">
                <div className="">
                  <span>
                    <img src={SuccessImg} alt="EXITO" />{" "}
                  </span>
                  <h2 className="text fnt_bold mt-4">¡Compra exitosa!</h2>
                  <h5 className>
                    En breve recibirás un correo con la información de tu
                    compra.
                  </h5>
                </div>
                <div className="mt-5">
                  <p className="fw300">
                    <b>Número de pedido: </b>
                    {orderDetail.orderNumber}
                  </p>
                  <p className="fw300">
                    <b>Estado: </b>
                    {orderDetail.status}
                  </p>
                  <p className="fw300">
                    <b>Fecha: </b>
                    <Moment format="DD/MM/YYYY">{orderDetail.date}</Moment>
                  </p>
                </div>
                <div className="btn_actions_details mt-5">
                  <Link className="btn secundary ml-1 mr-1 mb-2" to="/discount">
                    Ir a tienda
                  </Link>
                  <Link
                    className="btn primary ml-1 mr-1 mb-2"
                    to="/shopping-history"
                  >
                    Mis compras
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countrySetting: state.countrySetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartQuantity: () => dispatch(setCartQuantity()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
