import { callApi } from './service'
import swal from "sweetalert"

export const useJobRole = {
    createJobRole,
    updateJobRole,
    deleteJobRole,
    getJobRoles
};
async function createJobRole(params) {
    return callApi('/backoffice/JobRole', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getJobRoles() {
    return callApi('/backoffice/JobRole', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateJobRole(params) {
    return callApi('/backoffice/JobRole', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteJobRole(params) {
    return callApi('/backoffice/JobRole', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El puesto se desactivo correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar el puesto", icon: "error" });
        }
    });
}