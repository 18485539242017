import React, { Fragment, useEffect, useState } from 'react';
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'
import SelectMessageTo from './selectMessageTo';
import { messagePrivilegeService } from '../../services/messagePrivilige.service'

export default function ConfigurePrivileges(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState('');
  const [userSelec, setuserSelec] = useState([]);//USUARIOS QUE VIENEN DEL DROPDOWN
  const [cleanForm, setCleanForm] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userNoPrivilege, setUserNoPrivilege] = useState([]);//ARRAY PARA REMOVER PRIVILEGIOS A USUARIOS
  const [userPrivilige, setUserPrivilige] = useState([])//USUARIOS CON PRIVILEGIOS
  const [needUpdate, setNeedUpdate] = useState(0);

  useEffect(() => {
    messagePrivilegeService.getAllUserPrivileges().then((data) => {
      setUserPrivilige(data);
    })
  }, [])

  const addPrivileges = () => {
    let body = [];
    userSelec.forEach(element => {
      body.push({ id: element.UserProfileId })
    });
    messagePrivilegeService.addMessagePrivileges(body).then(() => { updateUserList() })
    setCleanForm(!cleanForm)
  }

  const removePrivileges = () => {
    userNoPrivilege.forEach(element => {
      messagePrivilegeService.deleteMessagePrivileges(element.userProfileId).then(() => {
        updateUserList()
      })
    });
    setUserNoPrivilege([])
  }

  const handleSelectUser = (event, data) => {
    data.check = event.target.checked;
    if (data.check) {
      setUserNoPrivilege([...userNoPrivilege, { userProfileId: data.id }])
    } else {
      let result = userNoPrivilege.filter((item) => item.userProfileId != data.id);
      setUserNoPrivilege(result);
    }
    event.stopPropagation()
  }

  const updateUserList = () => {
    messagePrivilegeService.getAllUserPrivileges().then((data) => {
      setUserPrivilige(data);
    })
  }

  return (
    <Fragment>
      <fieldset>
        <legend className="text fnt_medium">Liderazgo:</legend>
        <div className="row align-items-center">
          <div /*className="col-md-5" */ className="col-md">
            <div className="form-group">
              <SelectMessageTo setUsersTo={setuserSelec} cleanForm={cleanForm} userPrivilige={userPrivilige} filterPrivilege={true} needUpdate={needUpdate} setNeedUpdate={setNeedUpdate}/>
              <button className="btn secundary minimum" onClick={() => {addPrivileges();setNeedUpdate(1)}}>Agregar</button>
            </div>

          </div>
        </div>
      </fieldset>

      <div className="row mt-4">
        <div className="col-md-5">
          <h4>Eliminar privilegios</h4>
          <button className="btn secundary  minimum" onClick={() => removePrivileges()}>Eliminar</button>
        </div>
      </div>

      {/* Tabla de usuarios habilitados para enviar mensajes */}
      <div>
        <table className="wrapper_table table table-striped table-hover table-sm">
          <tr>
            <th>Nombre</th>{/*  */}
            <th>Permisos</th>
          </tr>
          {userPrivilige &&
            <Fragment>
              {userPrivilige.map((user, index) =>
                <tr key={index}>
                  <td className="link_data td_user">
                    <div className="box_avatar_user">
                      <span className="avatar">
                        <img src={user.urlProfileImage} />
                      </span>
                    </div>
                    {user.middleName + " " + user.lastName + " " + user.firstName}
                  </td>
                  <td>
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" onClick={(event) => handleSelectUser(event, user)} checked={user.check}>
                      </input>
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          }
        </table>
      </div>
      {/* Aside con la informacion del usuario seleccionado */}
    </Fragment>
  )
}