import React, { useState, useEffect } from 'react';
import { ToolDiscount } from '../../../components/toolDiscount/toolDiscount'
import PurchasedItem from './purchasedItem';
import { PaginatedPage } from './pagination';
import './companyShoppingHistory.scss'
import { ecommerceService } from '../../../services/ecommerce.service';
import { backendService } from "../../../services/backend.service";
import moment from 'moment';
import { Tooltip } from 'reactstrap';

export function CompanyShoppingHistory() {

    const [shoppingHistory, setShoppingHistory] = useState([]);
    const [userProfileBalance, setUserProfileBalance] = useState({ creditBalance: 0, prepaidBalance: 0 });
    const [datesFilter, setDatesFilter] = useState({ startDate: moment().startOf('month').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });

    const [page, setPage] = useState(1);
    const totalPages = shoppingHistory.length > 0 ? shoppingHistory.length : 1;
    const isMoreThanThreeMonths = moment(datesFilter.startDate).diff(datesFilter.endDate, 'months', true) < (0 - 3);

    useEffect(() => {
        ecommerceService.getPurchaseOrdersFromCompany(datesFilter.startDate, datesFilter.endDate)
            .then(result => setShoppingHistory(result));

        getUserProfileBalance();

    }, []);


    const getUserProfileBalance = () => {
        backendService.getUserProfileBalance()
            .then(result => setUserProfileBalance(result));
    }

    const filterPurchaseOrders = () => {
        ecommerceService.getPurchaseOrdersFromCompany(datesFilter.startDate, datesFilter.endDate)
            .then(result => { setShoppingHistory(result); getUserProfileBalance(); });
    }

    const downloadCSV = () => {
        //dispatch(toggleBlocking());
        ecommerceService.downloadSalesAsCSV(datesFilter.startDate, datesFilter.endDate);
    }

    const endDateValidator = !datesFilter.startDate || !datesFilter.endDate || moment(datesFilter.startDate).isAfter(moment(datesFilter.endDate)) || isMoreThanThreeMonths;


    const startDateValidator = !datesFilter.startDate || !datesFilter.endDate || moment(datesFilter.startDate).isAfter(moment(datesFilter.endDate));

    return (
        <section className="wrapper_shoppingHistory padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium">Reportes</h2>
                    <ToolDiscount userProfileBalance={userProfileBalance} />
                </div>
            </div>
            <div className="contain_shop_history">
                <div className="boxes_hop_history">
                    <label for="startDate">Filtrar por fecha</label>
                    <div className='dateFilter'>
                        <input className="date-control" type="date" id="startDate" name="StartDate" value={datesFilter.startDate} onChange={(e) => setDatesFilter({ ...datesFilter, startDate: e.target.value })} />
                        <input className="date-control" type="date" id="endDate" name="EndDate" value={datesFilter.endDate} onChange={(e) => setDatesFilter({ ...datesFilter, endDate: e.target.value })} />
                        <button className='button-control' disabled={startDateValidator} type="button" onClick={filterPurchaseOrders}>Buscar</button>
                        <button id='reportGeneratorBtn' className="btn secundary" disabled={endDateValidator} onClick={() => downloadCSV()} >
                            <p><i className="fas fa-download"></i> CSV</p>
                        </button>
                    </div>
                    {
                        shoppingHistory.length > 0
                            ? shoppingHistory[page - 1].map(item => {
                                return <PurchasedItem {...item} />
                            })
                            : ""
                    }
                    <div className="paginated_page">
                        <PaginatedPage
                            totalPages={totalPages}
                            currentPage={page}
                            onChange={page => setPage(page)}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
