import React, { useState, useRef, useEffect } from 'react'
import { FormGroup, Label } from "reactstrap"
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'

import { companyPostService } from '../../services/companyPost.service'
import { SelectCustom } from '../../components/Helpers/SelectCustom'
import swal from "sweetalert"

export default function SelectMessageTo(props) {
  const [localfiles, setLocalFiles] = useState([]);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [userSelec, setuserSelec] = useState([]);
  const [checked, setChecked] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
    handleCleanForm();
  }, [props.cleanForm])

  useEffect(() => {
    if (props.needUpdate == 1) {
      setDataUsers([])
      props.setNeedUpdate(0)
    }
  }, [props.needUpdate])

  const onSave = () => {
    let model = {
      CompanyPostId: parseInt(inputs.CompanyPostId), Title: inputs.Title, Subtitle: inputs.Subtitle, Text: inputs.Text, ListUser: userSelec
    }

    if (parseInt(inputs.CompanyPostId) === 0) {
      if (userSelec.length > 0) {
        companyPostService.create(model, localfiles, localAttachments).then((result) => {
          gridRef.current.refreshDataGrid();
        });
      } else {
        swal({ text: "Debes elegir al menos un empleado", icon: "error" });
      }
    } else {
      companyPostService.update(model, localfiles, localAttachments).then((result) => {
        gridRef.current.refreshDataGrid();
      });
    }
    setuserSelec([]);
    setDataUsers([]);
    setInputs({});
    setLocalFiles([]);
    setLocalAttachments([]);
  }
  const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

  const handleOnSearch = () => {
    let model = {
      DepartmentId: parseInt(!inputs.DepartmentId ? 0 : inputs.DepartmentId), JobRoleId: parseInt(!inputs.JobRoleId ? 0 : inputs.JobRoleId)
    }
    companyPostService.getPostUsers(model)
      .then((result) => {
        if (result) {
          if (props.filterPrivilege) {
            let users = result
            if (props.userPrivilige.length > 0) {
              props.userPrivilige.map((user) => {
                users = users.filter((item) => item.userProfileId != user.id)
                setDataUsers(users)
              })
            }
            else {
              setDataUsers(users)
            }
          }
          else {
            setDataUsers(result);
          }
        }
      });
  }

  const handleSelectUser = (event, data) => {
    data.check = event.target.checked;
    if (data.check) {
      setuserSelec([...userSelec, { UserProfileId: data.userProfileId, EmployeeNo: data.employeeNo }])
      props.setUsersTo([...userSelec, { UserProfileId: data.userProfileId, EmployeeNo: data.employeeNo }])
    } else {
      let result = userSelec.filter((item) => item.UserProfileId != data.userProfileId);
      setuserSelec(result);
      props.setUsersTo(result)
    }
    event.stopPropagation()
  }

  const handleSelectUserAll = (event) => {
    setuserSelec([])
    setChecked(!checked)
    dataUsers.map(data => {
      if (event.target.checked && !data.check) {
        userSelec.push({ UserProfileId: data.userProfileId, EmployeeNo: data.employeeNo })
        setuserSelec([...userSelec])
        props.setUsersTo([...userSelec])
      }
      data.check = event.target.checked
    })
  }

  const handleCleanForm = () => {
    setuserSelec([]);
    dataUsers.map(data => {
      data.check = false;
    })
    setChecked(false);
    props.setUsersTo([])
  }

  return (
    <fieldset>
      <legend className="text fnt_medium">Destinatarios:</legend>
      <div className="row align-items-center">
        <div className="col-lg-4 col-sm-6">
          <FormGroup>
            <Label for="DepartmentId">Departamento</Label>
            <SelectCustom selectName="DepartmentId" handleOnChange={handleInputChange} selectValue={inputs.DepartmentId}
              method="Department" textField="departmentName" valueField="departmentId" />
          </FormGroup>
        </div>
        <div className="col-lg-4 col-sm-6">
          <FormGroup >
            <Label for="JobRoleId">Roles</Label>
            <SelectCustom selectName="JobRoleId" handleOnChange={handleInputChange} selectValue={inputs.JobRoleId}
              method="JobRole" textField="name" valueField="jobRoleId" />
          </FormGroup>
        </div>
        <div className="col-lg-4 col-sm-6">
          <button type="button" className="btn secundary minimum" onClick={handleOnSearch}>Consultar</button>
        </div>

        <div className="col-12">
          <div className="table-responsive mt-4">
            <table className="wrapper_table table table-sm table-hover">
              <thead>
                <tr>
                  <th className="text fnt_medium">No. Empleado</th>
                  <th className="text fnt_medium">Nombre de empleado</th>
                  <th className="text fnt_medium">Departamento</th>
                  <th className="text fnt_medium text-center">
                    {dataUsers.length > 0 ?
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checkboxAll" className="custom-control-input" onClick={handleSelectUserAll} checked={checked} />
                        <label className="custom-control-label" for="checkboxAll">Seleccionar</label>
                      </div> : "Seleccionar"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataUsers.map(item => (
                  <tr key={item.userProfileId}>
                    <td>{item.employeeNo}</td>
                    <td>
                      {
                        //(item.nickname == null || item.nickname == '') ?
                                item.middleName + ' ' + item.lastName + ' ' + item.firstName 
                            // : item.nickname
                      }

                        </td>
                    <td>{item.department.departmentName}</td>

                    <td className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="Selected" className="custom-control-input" id={`check-${item.userProfileId}`} onClick={(event) => handleSelectUser(event, item)} checked={item.check} />
                        <label className="custom-control-label" for={`check-${item.userProfileId}`}> </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </fieldset>
  )
}