import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const companyPostService = {
    getPostUsers
};

async function getPostUsers(params) {
    return callApi('/backoffice/UserProfile/GetByFilter/' + params.DepartmentId + '/' + params.JobRoleId, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}