import React, { useState, useEffect } from 'react';
import ItemPayMethod from './itemPayMethod/itemPayMethod'
import './boxPayMethod.scss'
// import { ecommerceService } from "../../services/ecommerce.service";
import { companyService } from '../../services/control/company.service';
import { useSelector } from 'react-redux';

const BoxPayMethod = ({ onCloseClick, showBalancePay, showBalanceOnly, productPurchase }) => {
    const [companiesData, setCompaniesData] = useState([]);
    const companyId = useSelector((state) => state.userSettings.companyId);
    useEffect(() => {        
        companyService.getByCompanyId(companyId)
            .then((result) => {
                    setCompaniesData(result);
            })
    }, [companyId]);

    return (
        <div className="box_paymethod">
            {/* {
                paymentMethods.map(method => {
                    if (showBalanceOnly)
                    {
                        return (method.paymentMethodId == 15 && showBalancePay)  && <ItemPayMethod key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} showBalanceOnly = { true } productPurchase = { productPurchase } />
                    }   
                    else
                    {
                        return (method.paymentMethodId != 15 || showBalancePay)  && <ItemPayMethod key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} />
                    }                 
                })
            } */}
            {
                companiesData.map(method => {   
                    if (showBalanceOnly)
                    {
                        return (method.paymentMethodId == 15 && showBalancePay)  && <ItemPayMethod key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} showBalanceOnly = { true } productPurchase = { productPurchase } />
                    }   
                    else
                    {
                        return (method.paymentMethodId != 15 || showBalancePay) && <ItemPayMethod key={method.paymentMethodId} onCloseClick={onCloseClick} method={method} />
                    }              
                })
            }
        </div>
    )
}

export default BoxPayMethod