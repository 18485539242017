import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Collapse } from 'reactstrap';

import './menuAside.scss';
import IcoHome from '../../assets/imgs/iconos/ico_home.png'
import IcoProfile from '../../assets/imgs/iconos/ico_profile.png'
import IcoMessage from '../../assets/imgs/iconos/ico_message.png'
import IcoAdmin from '../../assets/imgs/iconos/ico_admin.png'
import IcoDiscount from '../../assets/imgs/iconos/ico_discount.png'
import { useSelector } from 'react-redux';

export function MenuAside({ activeMenu, closeAll }) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => setIsOpen(!isOpen);

    const MenuUsuario = () => {
        return <div>
            <li>
                <NavLink className="menu_item" to="/home" onClick={closeAll}>
                    <span className="ico_imgs">
                        <img src={IcoHome} alt="Menu" />
                    </span>
                    <span>Muro</span>
                </NavLink>
            </li>
            <li>
                <NavLink className="menu_item" to="/profile" onClick={closeAll}>
                    <span className="ico_imgs">
                        <img src={IcoProfile} alt="Menu" />
                    </span>
                    <span>Mi perfil</span>
                </NavLink>
            </li>
            <li>
                <NavLink className="menu_item" to="/messages" onClick={closeAll}>
                    <span className="ico_imgs">
                        <img src={IcoMessage} alt="Menu" />
                    </span>
                    <span>Mensajes</span>
                </NavLink>
            </li>
            <li>
                <NavLink className="menu_item" to="/discount" onClick={closeAll}>
                    <span className="ico_imgs">
                        <img src={IcoDiscount} alt="Menu" />
                    </span>
                    <span>Tienda</span>
                </NavLink>
            </li>
        </div>
    }

    const MenuIntermediario = () => {
        return <li>
            <div className="menu_item" onClick={toggleCollapse}>
                <span className="ico_imgs">
                    <img src={IcoAdmin} alt="Menu" />
                </span>
                <span>Intermediario <i className="fas fa-caret-down"></i></span>
            </div>
            <Collapse isOpen={isOpen}>
                <ul className="ul_collapse">
                    <li>
                        <NavLink className="submenu_item" to="/control" onClick={closeAll}>Control</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/AdminCompany" onClick={closeAll}>Empresas</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/balance" onClick={closeAll}>Saldos</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/products" onClick={closeAll}>Productos</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/discount-user" onClick={closeAll}>Descuentos Usuarios</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/users" onClick={closeAll}>Usuarios</NavLink>
                    </li>
                </ul>
            </Collapse>
            <hr />
            <MenuUsuario />
        </li>
    }

    const MenuEmpresa = () => {
        return <li>
            <div className="menu_item" onClick={toggleCollapse}>
                <span className="ico_imgs">
                    <img src={IcoAdmin} alt="Menu" />
                </span>
                <span>Empresa <i className="fas fa-caret-down"></i></span>
            </div>
            <Collapse isOpen={isOpen}>
                <ul className="ul_collapse">
                    <li>
                        <NavLink className="submenu_item" to="/control" onClick={closeAll}>Control</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/balance" onClick={closeAll}>Saldos</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/discount-user" onClick={closeAll}>Descuentos Usuarios</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/users" onClick={closeAll}>Usuarios</NavLink>
                    </li>
                </ul>
            </Collapse>
            <hr />
            <MenuUsuario />
        </li>
    }

    const MenuAdministrador = () => {
        return <li>
            <div className="menu_item" onClick={toggleCollapse}>
                <span className="ico_imgs">
                    <img src={IcoAdmin} alt="Menu" />
                </span>
                <span>Administrador <i className="fas fa-caret-down"></i></span>
            </div>
            <Collapse isOpen={isOpen}>
                <ul className="ul_collapse">
                    <li>
                        <NavLink className="submenu_item" to="/AdminCompany" onClick={closeAll}>Empresas</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/control" onClick={closeAll}>Control</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/balance" onClick={closeAll}>Saldos</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/products" onClick={closeAll}>Productos</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/discount-user" onClick={closeAll}>Descuentos Usuarios</NavLink>
                    </li>
                    <li>
                        <NavLink className="submenu_item" to="/users" onClick={closeAll}>Usuarios</NavLink>
                    </li>
                </ul>
            </Collapse>
            <hr />
            <MenuUsuario />
        </li>
    }

    return (
        <aside className={`wrapper_menu_aside ${activeMenu}`}>
            <nav className="nav_menu_items">
                <ul>
                    {{
                        'Administrador':
                            <MenuAdministrador />,
                        'Admin_Intermediario':
                            <MenuIntermediario />,
                        'Admin_Empresa':
                            <MenuEmpresa />,
                        'Usuario':
                            <MenuUsuario />
                    }[roleName]}
                </ul>
            </nav>
        </aside>
    )
}
