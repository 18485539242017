import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchProducts } from './searchProducts';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from "react-redux";

import './products.scss'

export function Products() {
    const roleName = useSelector((state) => state.userSettings.roleName);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <section className="wrapper_survey_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Productos</h2>
                    </div>
                    <div className="box_actions">
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="btn primary" caret>
                                <i className="fas fa-cart-arrow-down"></i> Productos
                                </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    <Link to="/details-product/0">
                                        <i className="fas fa-user-tag"></i> Asignar
                                        </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
            <div>
                <SearchProducts />
            </div>

        </section>
    )
}