import React, { useEffect, useState } from 'react';
import { userService } from '../../services/user.service'
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';


export function Preferences(props) {
    const { register, handleSubmit } = useForm();
    const companyId = useSelector(state => state.userSettings.companyId)
    const PrimaryColor = useSelector(state => state.userSettings.primaryColor);
    const SecondaryColor = useSelector(state => state.userSettings.secondaryColor);
    const [pref, setPreferences] = useState({Preferences: "", ProfileImage: null, Mobile: null, Notification: false})
    const [changePreference, setChangePreference] = useState(false)
    const [userPreferences, setUserPreferences] = useState({ PrimaryColor: null, Notification: false })
    const [userNotification, setUserNotification] = useState(true)


    const handleChange = (event) => {
        if (event.target.id == "PrimaryColor" && userPreferences.PrimaryColor != "PrimaryColor") {
            setChangePreference(true);
            setUserPreferences({ PrimaryColor: "PrimaryColor"  });
            // setPreferences( {... pref, Preferences: JSON.stringify({ "PrimaryColor": PrimaryColor })
            //             });
            setPreferences( {... pref, Preferences: "PrimaryColor" });
        }
        if (event.target.id == "SecondaryColor" && userPreferences.PrimaryColor != "SecondaryColor") {
            setChangePreference(true);
            setUserPreferences({ PrimaryColor: "SecondaryColor",});
            // setPreferences({... pref, Preferences: JSON.stringify({ "PrimaryColor": SecondaryColor })
            //             });
            setPreferences({... pref, Preferences: "SecondaryColor" });
        }
        if (event.target.id =="notification"){
            // debugger
            setChangePreference(true);
            setUserNotification(event.target.checked)
            setPreferences( {... pref, Notification : event.target.checked });
        }
    }

        
    
    
  
    useEffect(() => {
        // debugger
        if (props.preferences != null) {
            // let obj = JSON.parse(props.preferences)
            setPreferences({... pref, Preferences: props.preferences, Notification: props.notification })
            // setUserPreferences({ PrimaryColor: obj.PrimaryColor });
            setUserPreferences({ PrimaryColor: props.preferences });
            setUserNotification(props.notification)
        }
        else
        {
            // setPreferences({... pref, Preferences: JSON.stringify({ "PrimaryColor": PrimaryColor }), Notification: props.notification })
            setPreferences({... pref, Preferences: "PrimaryColor", Notification: props.notification })
            // setUserPreferences(   { PrimaryColor: PrimaryColor });
            setUserPreferences(   { PrimaryColor: "PrimaryColor" });
            setUserNotification(props.notification)

        }
    },[props]);

    const styles = {
        primary: {
            background: PrimaryColor
        },
        secondary: {
            background: SecondaryColor
        }
    }
    const onSubmit = (data, e) =>
    {
        // debugger
        userService.editProfile(pref, companyId)
            .then((result) => {
                if (result)
                {
                    window.location.reload();
                }
            });
    }
    
    return (
        <div className="wrapper_preferencias">
            <h5 className="text fnt_medium"><i className="fas fa-cog"></i> Preferencias</h5>
            <hr className="mt-0" />
            <div className="">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="Theme">Tema</label>
                    <div className="custom-control custom-radio" >
                        {/* <input type="radio" id="PrimaryColor" name="Color" className="custom-control-input" onClick={handleChange} checked={userPreferences.PrimaryColor === "PrimaryColor"} value={PrimaryColor + " " + userPreferences.PrimaryColor} /> */}
                        <input type="radio" id="PrimaryColor" name="Color" className="custom-control-input" onClick={handleChange} checked={userPreferences.PrimaryColor === "PrimaryColor"} value={"PrimaryColor"} />

                        <label className="custom-control-label" for="PrimaryColor">
                            <span>Color Primario</span>
                            <span className="span_drop" style={styles.primary}></span>
                        </label>
                    </div>
                    <div className="custom-control custom-radio mt-2">
                        {/* <input type="radio" id="SecondaryColor" name="Color" className="custom-control-input" onClick={handleChange} checked={userPreferences.PrimaryColor == "SecondaryColor"} value={SecondaryColor} /> */}
                        <input type="radio" id="SecondaryColor" name="Color" className="custom-control-input" onClick={handleChange} checked={userPreferences.PrimaryColor == "SecondaryColor"} value={"SecondaryColor"} />
                        <label className="custom-control-label" for="SecondaryColor">
                            <span>Color Secundario</span>
                            <span className="span_drop" style={styles.secondary}></span>
                        </label>
                    </div>
                    <br></br>
                    {/*<div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                 onChange={handleChange}
                                                 checked= {userNotification}
                                                name="Notification"
                                                id="notification"
                                            />
                                            <label className="custom-control-label" htmlFor="notification">Deseo recibir notificationes</label>
                                        </div>*/}
                    {changePreference ? <button type="submit" className="btn primary minimum" >Guardar</button> : null}
                </form>
            </div>
        </div>

    )
}