import { callApi } from './service'

export const attendeeService = {
    createAttendee,
    updateAttendee,
    getAttendee,
    deleteAttendee,
    getAttendeeUsers
};


async function createAttendee(params) {
    return callApi('/backoffice/Attendee', 'POST', params);
}

async function updateAttendee(params) {
    return callApi('/backoffice/Attendee', 'PUT', params);
}

async function getAttendee() {
    return callApi('/backoffice/Attendee', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getAttendeeUsers(params) {
    return callApi('/backoffice/Attendee/getAttendeeUsers/' + params.BranchOfficeId + '/'
        + params.DepartmentId + '/' + params.JobRoleId, 'GET').then((result) => {

            if (result.responseCode == '00') {
                return result.data;
            } else {
                alert(result.responseMessage);
                return null;
            }

        });
}


async function deleteAttendee(params) {
    return callApi('/backoffice/Attendee', 'DELETE', params);
}