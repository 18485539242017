import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import swal from "sweetalert"
import { ecommerceService } from '../../../services/ecommerce.service'
import { cartHelper } from '../../../helpers/cart.helper'
import redpaylogo from '../../../assets/imgs/paymentMethods/red-pay.png'
import balancepay from '../../../assets/imgs/paymentMethods/balance-pay.png'

const ItemPayMethod = ({ onCloseClick, method, showBalanceOnly, productPurchase }) => {
    // const dispatch = useDispatch();
    const history = useHistory();
    const [onlyOneClick, setOnlyOneClick] = useState(false)
    
    let model = {
        Products: [],
        PaymentMethodId: method.paymentMethodId,
        AplicationId: 10
    };

    const payOrder = () => {
        if (!onlyOneClick) {
            setOnlyOneClick(true);

            if  (!showBalanceOnly)
            {
                model = {
                    Products: cartHelper.getItems(),
                    PaymentMethodId: method.paymentMethodId
                };
            }
            else {
                model.Products.push(productPurchase);
            }


                if (model.Products.length > 0 ) {
                    if (showBalanceOnly){
                         ecommerceService.payOrderExternal(model)
                        .then(function (result) {
                            if (result != undefined) {
                                if(result.responseCode !== "00")
                                {
                                    setOnlyOneClick(false);
                                    swal({ text: result.responseMessage, icon: "error" }).then(o=> {
                                        if (  result.responseCode !== "-1")
                                            window.location.href = result.data
                                    }) 
                                }
                                else{
                                    setOnlyOneClick(false);
                                    window.location.href = result.data
                                }
                            } else {
                                setOnlyOneClick(false);
                                swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                            }
                        });
                    }
                    else{
                        ecommerceService.payOrder(model)
                        .then(function (result) {
                            if (result != undefined) {
                                setOnlyOneClick(false);
                                if (onCloseClick != undefined) onCloseClick();
                                window.location.href = result
                            } else {
                                setOnlyOneClick(false);
                                swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                            }
                        });
                    }

                } else {
                    swal({ text: "No hay ningún producto en el carrito de compras", icon: "error" })
                    setOnlyOneClick(false);
                }

            }
        }

    return (
        <>
        {method.paymentMethodId == 0 ? "Solicite al administrador un método de pago" : 
        <button
            className="item_paymethod trans"
            onClick={payOrder}
        >
        <img src={method.paymentMethodId == 13 ? redpaylogo : balancepay } alt="Paga con Red Pay" /> 
        </button>
    }
    </>
    )
}

export default ItemPayMethod